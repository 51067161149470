/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row, Container, Modal } from "react-bootstrap";
import styled from "styled-components";
import { CardLogBootstrapStyled } from "../../../../../styledComponents/CardBootstrapLog";
import { COLOR_GRAY_TEXT } from "../../../../../constants/Colors";
import { priceCalculation, turnString } from "../../../../../utils/helpers";
import LogButton from "../../../../../components/atoms/LogButton";
import ModalMapViewByPropsDc from "./ModalMapViewByPropsDc";
const PAYMENT_TYPE_NAME = process.env.REACT_APP_TOMADOR_PAYMENT_NAME;
const TAXES_LABEL = process.env.REACT_APP_TAXES_LABEL;

const DivInfoUser = styled.div`
  display: flex;
  align-items: center;
`;

const DivBtnShowInfo = styled.div`
  min-width: 100px;
  height: 50px;
  color: rgb(163, 199, 59);
  background-color: #effbcd;
  border-radius: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  padding: 10px;
`;

const convertItemIntoCommatsDot = (array) => {
  return array?.length > 0
    ? [array?.slice(0, -1).join(", "), array?.slice(-1)[0]].join(
        array?.length < 2 ? "" : " y "
      )
    : "";
};

const ModalLogStyled = styled(Modal)`
  opacity: 1;
  width: 100%;

  .modal-content {
    overflow-y: overlay;
    position: relative;
    max-height: 90vh;
    border-radius: 15px !important;
    padding: 0 10px;
    box-sizing: border-box;

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
      width: 8px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #d62929;
    }
  }
  p {
    color: ${COLOR_GRAY_TEXT};
  }
`;

const TravelDetailModalDc = ({
  isShow,
  handleClose,
  travelData,
  handlePostulation,
}) => {
  const [entity, setEntity] = useState(null);
  const [isModalMapView, setIsModalMapView] = useState(false);

  useEffect(() => {
    getFetch();
  }, []);
  const getFetch = async () => {
    if (travelData) {
      let namesTruckFeatures = travelData.truck_features.map(
        (tf) => tf.truck_characteristic_name
      );
      let namesTruckAccessories = travelData.truck_accessories?.map(
        (tf) => tf.accesory_name
      );
      let namesTruckTypes = travelData.truck_type.map(
        (tf) => tf.truck_type_name
      );
      setEntity({
        ...travelData,
        truck_features: convertItemIntoCommatsDot(namesTruckFeatures),
        truck_accessories: convertItemIntoCommatsDot(namesTruckAccessories),
        truck_type: convertItemIntoCommatsDot(namesTruckTypes),
      });
    }
  };

  return entity ? (
    <ModalLogStyled
      className="modal-exit"
      centered
      size={"xl"}
      show={isShow}
      onHide={handleClose}
      onClose={handleClose}
    >
      <Modal.Body>
        <Container fluid>
          <Row className="mt-2">
            <Col md="6">
              <DivInfoUser>
                <h2 className=" text-primary font-weight-bold mb-0">
                  Viaje ID: {entity.id_trip}
                </h2>
              </DivInfoUser>
            </Col>
            <Col
              md="6"
              className="d-flex"
              style={{ justifyContent: "space-evenly", alignItems: "center" }}
            >
              <DivBtnShowInfo>
                {entity.product?.product_type_name}
              </DivBtnShowInfo>

              <DivBtnShowInfo>
                {`Distancia: ${entity.kilometers} km`}
              </DivBtnShowInfo>

              <LogButton
                title="Ver mapa"
                border="curve"
                onClick={() => {
                  setIsModalMapView(true);
                }}
                color="transparent"
                style={{ color: "black" }}
              ></LogButton>
            </Col>
          </Row>
          <Row>
            <Col md="6" className="mt-4">
              <CardLogBootstrapStyled className="shadow-6dp p-4">
                <Row>
                  <Col className="d-flex align-items-center">
                    <div className="circle-icon">
                      <span className="icon-ping text-primary"></span>
                    </div>
                    <p className="mb-0 text-primary h4">
                      <strong>Origen</strong>{" "}
                    </p>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    <p className="mb-0 text-capitalize">
                      {" "}
                      <strong>Lugar:</strong>{" "}
                      {entity?.origin_site?.address?.toLowerCase()}
                    </p>
                    <p className="mb-0 text-capitalize">
                      {" "}
                      <strong>Tipo de origen:</strong>{" "}
                      {entity.origin?.toLowerCase()}
                    </p>
                    <p className="mb-0 text-capitalize">
                      {" "}
                      <strong>Fecha:</strong>{" "}
                      {moment(entity.withdraw_date).format("DD/MM/YYYY")}, turno{" "}
                      {turnString(entity.withdraw_turn)?.toLowerCase()}
                    </p>
                  </Col>
                </Row>
              </CardLogBootstrapStyled>
            </Col>
            <Col md="6" className="mt-4">
              <CardLogBootstrapStyled className="shadow-6dp p-4">
                <Row>
                  <Col className="d-flex align-items-center">
                    <div className="circle-icon">
                      <span className="icon-ping text-primary"></span>
                    </div>
                    <p className="mb-0 text-primary h4">
                      <strong>Destino</strong>{" "}
                    </p>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    <p className="mb-0 text-capitalize">
                      {" "}
                      <strong>Lugar:</strong>{" "}
                      {entity.destiny_site.address?.toLowerCase()}
                    </p>
                    <p className="mb-0 text-capitalize">
                      {" "}
                      <strong>Tipo de destino:</strong>{" "}
                      {entity.destiny?.toLowerCase()}
                    </p>
                    <p className="mb-0 text-capitalize">
                      {" "}
                      <strong>Fecha:</strong>{" "}
                      {moment(entity.delivery_date).format("DD/MM/YYYY")}, turno{" "}
                      {turnString(entity.delivery_turn)?.toLowerCase()}
                    </p>
                  </Col>
                </Row>
              </CardLogBootstrapStyled>
            </Col>
          </Row>
          <Row>
            <Col md="6" className="mt-4 ">
              <CardLogBootstrapStyled
                className="shadow-6dp p-4"
                style={{ height: "100%" }}
              >
                <Row>
                  <Col className="d-flex align-items-center">
                    <div className="circle-icon">
                      <span className="icon-truck text-primary"></span>
                    </div>
                    <p className="mb-0 text-primary h4">
                      <strong>Camiones</strong>{" "}
                    </p>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    {/* <p className="mb-0">
                      <strong>Camiones solicitados:</strong>{" "}
                      {entity.trucks_number}
                    </p> */}
                    <p className="mb-0 text-capitalize">
                      <strong>Carga por camión:</strong> {entity.truck_charge}{" "}
                      {entity.unit_truck_charge}
                    </p>
                    <p className="mb-0">
                      <strong>Tipo:</strong> {entity.truck_type}
                    </p>
                    <p className="mb-0">
                      <strong>Características y accesorios:</strong>{" "}
                      {entity.truck_features
                        ? entity.truck_features + ", "
                        : ""}
                      &nbsp;
                      {entity.truck_accessories}
                    </p>
                  </Col>
                </Row>
              </CardLogBootstrapStyled>
            </Col>
            <Col md="6" className="mt-4">
              <CardLogBootstrapStyled
                className="shadow-6dp p-4"
                style={{ height: "100%" }}
              >
                <Row>
                  <Col className="d-flex align-items-center">
                    <div className="circle-icon">
                      <span className="icon-cash text-primary"></span>
                    </div>
                    <p className="mb-0 text-primary h4">
                      <strong>Tarifas</strong>{" "}
                    </p>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    <p className="mb-0 text-capitalize">
                      <strong>
                        {entity.discount_trip || entity.discount_dador
                          ? priceCalculation(entity.price_per_ton)
                          : entity.price_per_ton}
                        {"/" + entity.unit_truck_charge?.slice(0, 3)} (precio{" "}
                        {entity.price_type === "1" ? "abierto" : "cerrado"})
                      </strong>
                    </p>
                    <p className="mb-0">
                      <strong>Forma de pago:</strong>{" "}
                      {PAYMENT_TYPE_NAME || "E-cheq"}
                    </p>
                    <p className="mb-0">
                      <strong>Plazo de pagos en días:</strong>{" "}
                      {entity.payment_terms_days}
                    </p>
                  </Col>
                </Row>
              </CardLogBootstrapStyled>
            </Col>
          </Row>
          <Row>
            <Col>
              <h1 className="text-primary font-weight-bold h3 mt-3">
                Requisitos y condiciones:{" "}
                <span
                  className="font-weight-normal h4"
                  style={{ color: "black" }}
                >
                  {entity.requirements_conditions || "No disponible"}
                </span>
              </h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <h6 className=" h6 font-weight-bold font-italic m-0">
                <small style={{ color: "inherit" }}>
                  {TAXES_LABEL || "Montos netos sin impuestos"}
                </small>
              </h6>
            </Col>
          </Row>
          <Row>
            <Col md="3">
              {handlePostulation ? (
                <LogButton
                  className="mt-3 w-100"
                  title="Ofertar"
                  border="curve"
                  block="true"
                  size="lg"
                  onClick={handlePostulation}
                />
              ) : null}
            </Col>
            <Col md={{ span: 3, offset: 6 }}>
              <LogButton
                color="secondary"
                className="mt-3 w-100"
                title="Cancelar"
                border="curve"
                block="true"
                size="lg"
                onClick={handleClose}
              />
            </Col>
          </Row>

          {entity._id && isModalMapView && (
            <ModalMapViewByPropsDc
              isModalMapView={isModalMapView}
              entity={entity}
              onClosePostulation={(payload) => {
                setIsModalMapView(false);
              }}
            />
          )}
        </Container>
      </Modal.Body>
    </ModalLogStyled>
  ) : null;
};

export default TravelDetailModalDc;
