/* eslint-disable no-unused-vars */
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import {
  getUnitByIDAPI,
  getTruckTypeAPI,
  getTruckCharacteristicByTruckIdAPI,
} from "../../../../../api/modules";
import { Card, Form, Container, Row, Col } from "react-bootstrap";
import LogInput from "../../../../../components/atoms/LogInput";
import { useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import ContentHeader from "../../../components/molecules/ContentHeader";
import queryString from "query-string";
import LogSelect from "../../../../../components/atoms/LogSelect";
import DropZone from "../../../../../components/organisms/dropzone/DropZone";
import { units } from "../../../../../constants/staticSelectItems";
import LogSelectMulti from "../../../../../components/atoms/LogSelectMultiCustom";

const CardLogBootstrapStyled = styled(Card)`
  padding: 10px;
  border-radius: 15px;
`;

function DetailUnit() {
  const location = useLocation();
  const [unitType, setUnitType] = useState(null);
  const [truckTypes, setTruckTypes] = useState([]);
  const [truckCharac, setTruckCharac] = useState([]);
  const params = useParams();
  const [entity, setEntity] = useState({
    id_truckType: {},
  });
  let ProfileSchema = null;
  if (unitType === "semi") {
    ProfileSchema = Yup.object().shape(
      {
        domain: Yup.string().required("Dominio de la unidad es obligatorio"),
        brand: Yup.string(),
        model: Yup.string(),
        max_cargo: Yup.string().when("unit", (unit) => {
          if (unit)
            return Yup.string().required(
              "Cantidad de carga máxima es obligatorio"
            );
        }),
        unit: Yup.string().when("max_cargo", (max_cargo) => {
          if (max_cargo !== 0 && max_cargo === "")
            return Yup.string().required("Unidad de medida es obligatorio");
        }),
        id_truckType: Yup.string(),
        truck_features: Yup.array(),
      },
      [["max_cargo", "unit"]]
    );
  } else {
    ProfileSchema = Yup.object().shape(
      {
        domain: Yup.string().required("Dominio de la unidad es obligatorio"),
        brand: Yup.string().required("Marca es obligatorio"),
        model: Yup.string().required("Modelo es obligatorio"),
        max_cargo: Yup.string().when("unit", (unit) => {
          if (unit)
            return Yup.string().required(
              "Cantidad de carga máxima es obligatorio"
            );
        }),
        unit: Yup.string().when("max_cargo", (max_cargo) => {
          if (max_cargo !== 0 && max_cargo === "")
            return Yup.string().required("Unidad de medida es obligatorio");
        }),
      },
      [["max_cargo", "unit"]]
    );
  }

  useEffect(() => {
    const { id } = params;
    if (id) {
      fetchApi(id);
    }
    const { type } = queryString.parse(location.search);
    setUnitType(type);
    const getFetchData = async () => {
      // ------------ truckTypes
      const respTruckTypeAPI = await getTruckTypeAPI();
      const { truckTypes } = respTruckTypeAPI;
      try {
        const truckTypesFormatted = truckTypes.map((l) => ({
          _id: l._id,
          label: l.truck_type_name,
          value: l._id,
        }));
        setTruckTypes(truckTypesFormatted);
      } catch (error) {
        setTruckTypes([]);
      }
    };

    getFetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchApi = async (id) => {
    const response = await getUnitByIDAPI(id);
    const { truck } = response;
    const { type } = queryString.parse(location.search);

    setEntity(truck);

    if (type === "semi") {
      getFetchTruckCharsSelect(truck.id_truckType._id);
    }
  };

  const getStringType = () => {
    return unitType === "semi" ? "Acoplado" : "Camión";
  };

  const getFetchTruckCharsSelect = async (value) => {
    setTruckCharac([]);
    let truckCharsArr = [];
    const resp = await getTruckCharacteristicByTruckIdAPI(value);
    const { truckChars } = resp;
    for (const truckC of truckChars) {
      truckCharsArr.push(truckC);
    }
    const truckCharsFormatted = truckCharsArr.map((l) => ({
      _id: l._id,
      label: l.truck_characteristic_name,
      value: l._id,
    }));
    setTruckCharac(truckCharsFormatted);
  };

  const getCharacterTruck = (truckCharsArr) => {
    try {
      const truckCharsFormatted = truckCharsArr.map((l) => l._id);
      return truckCharsFormatted;
    } catch (error) {
      return [];
    }
  };

  return (
    <>
      <Container fluid>
        <Formik
          enableReinitialize
          initialValues={{
            domain: entity.domain || "",
            brand: entity.brand || "",
            model: entity.model || "",
            max_cargo: entity.max_cargo || "",
            unit: entity.unit || "",
            id_truckType: entity?.id_truckType?._id || "",
            truck_features: getCharacterTruck(entity.truck_features) || [],
          }}
          validationSchema={ProfileSchema}
        >
          {({
            values,
            errors,

            handleChange,
            handleBlur,

            setFieldValue,
          }) => (
            <Row>
              <ContentHeader className={`ml-3`} title="Detalle de la unidad" />
              <Col md="12">
                <CardLogBootstrapStyled className="shadow-4dp pt-3">
                  <CardLogBootstrapStyled.Body>
                    <Row>
                      <Col md="6">
                        <Form.Group>
                          <label className="text-primary font-weight-bold">
                            Ingresá el dominio de tu{" "}
                            {getStringType().toLowerCase()}
                          </label>
                          <LogInput
                            placeholder="Ingresá el dominio"
                            type="text"
                            name="domain"
                            onChange={(e) => {
                              const { value } = e.target;
                              setFieldValue(
                                "domain",
                                value ? value.toUpperCase() : ""
                              );
                            }}
                            onBlur={handleBlur}
                            errors={errors}
                            value={values.domain}
                            readOnly
                          />
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Form.Group>
                          <label className="text-primary font-weight-bold">
                            Ingresá la cantidad de carga maxima de tu{" "}
                            {getStringType().toLowerCase()}{" "}
                            <span className="text-infoLight">(opcional)</span>
                          </label>
                          <Row>
                            <Col md="6">
                              <LogInput
                                className="number-font"
                                placeholder="Ingresá un número"
                                type="text"
                                name="max_cargo"
                                onChange={(e) => {
                                  const { value } = e.target;
                                  const re = /^[0-9\b]+$/;
                                  if (value === "" || re.test(value)) {
                                    const number = parseFloat(value || 0);
                                    setFieldValue("max_cargo", number);
                                  } else {
                                    setFieldValue("max_cargo", 0);
                                  }
                                }}
                                onBlur={handleBlur}
                                errors={errors}
                                value={values.max_cargo}
                                readOnly
                              />
                            </Col>
                            <Col md="6">
                              <LogSelect
                                placeholder="Unidad de medida"
                                name="unit"
                                items={units}
                                onChange={(v) => {
                                  setFieldValue("unit", v);
                                }}
                                onBlur={handleBlur}
                                errors={errors}
                                value={values.unit}
                                readOnly
                              />
                            </Col>
                          </Row>
                        </Form.Group>
                      </Col>
                    </Row>
                  </CardLogBootstrapStyled.Body>
                </CardLogBootstrapStyled>
                <CardLogBootstrapStyled className="shadow-4dp pt-3 mt-4">
                  <CardLogBootstrapStyled.Body>
                    <Row>
                      <Col md="6">
                        <label className="text-primary font-weight-bold">
                          ¿ Qué modelo es tu {getStringType().toLowerCase()}?{" "}
                          <span className="text-infoLight">(opcional)</span>
                        </label>
                        <LogInput
                          placeholder="Ingresá el modelo"
                          type="text"
                          name="model"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          errors={errors}
                          value={values.model}
                          readOnly
                        />
                      </Col>
                      <Col md="6">
                        <label className="text-primary font-weight-bold">
                          Marca &nbsp;
                          <span className="text-infoLight">(opcional)</span>
                        </label>
                        <LogInput
                          placeholder="Ingresá la marca"
                          type="text"
                          name="brand"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          errors={errors}
                          value={values.brand}
                          readOnly
                        />
                      </Col>
                    </Row>
                  </CardLogBootstrapStyled.Body>
                </CardLogBootstrapStyled>
                {unitType === "semi" && (
                  <CardLogBootstrapStyled className="shadow-4dp pt-3 mt-4">
                    <CardLogBootstrapStyled.Body>
                      <Row>
                        <Col md="6">
                          <label className="text-primary font-weight-bold">
                            Tipo de camión &nbsp;
                            <span className="text-infoLight">(opcional)</span>
                          </label>
                          <LogSelect
                            placeholder="Tipo de camión"
                            name="id_truckType"
                            items={truckTypes}
                            onChange={async (value) => {
                              getFetchTruckCharsSelect(value);
                              setFieldValue("id_truckType", value);
                            }}
                            onBlur={handleBlur}
                            errors={errors}
                            value={values.id_truckType}
                            readOnly
                          />
                        </Col>
                        <Col md="6">
                          <label className="text-primary font-weight-bold">
                            Seleccioná las características{" "}
                            <span className="text-infoLight">(opcional)</span>
                          </label>
                          <LogSelectMulti
                            placeholder="Características"
                            className="mt-1"
                            name="truck_features"
                            items={truckCharac}
                            onChange={(v) => {
                              setFieldValue("truck_features", v);
                            }}
                            onBlur={handleBlur}
                            errors={errors}
                            value={values.truck_features}
                            readOnly
                          />
                        </Col>
                      </Row>
                    </CardLogBootstrapStyled.Body>
                  </CardLogBootstrapStyled>
                )}
                <CardLogBootstrapStyled className="shadow-4dp pt-3 mt-4">
                  <CardLogBootstrapStyled.Header className="pl-0 pt-4 pr-0 ml-4 mr-4 mb-0">
                    <label className="text-primary font-weight-bold mb-0">
                      Tarjeta verde
                    </label>
                  </CardLogBootstrapStyled.Header>
                  <CardLogBootstrapStyled.Body>
                    <Row>
                      <Col md="6">
                        <label className="back-primary font-weight-bold">
                          Foto Tarjeta Verde (Frente)
                        </label>
                        <DropZone
                          readOnly
                          placeholder="Subir foto de frente"
                          image={entity?.back_photo || null}
                        />
                      </Col>
                      <Col md="6">
                        <label className="back-primary font-weight-bold">
                          Foto Tarjeta Verde (Dorso)
                        </label>
                        <DropZone
                          readOnly
                          placeholder="Subir foto de dorso"
                          image={entity?.back_photo || null}
                        />
                      </Col>
                    </Row>
                  </CardLogBootstrapStyled.Body>
                </CardLogBootstrapStyled>
              </Col>

              <div className="clearfix"></div>
            </Row>
          )}
        </Formik>
      </Container>
    </>
  );
}

export default DetailUnit;
