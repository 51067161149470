import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { COLOR_GRAY_TEXT } from "../../../../constants/Colors";
import styled from "styled-components";
import LogButton from "../../../../components/atoms/LogButton";
import LogInput from "../../../../components/atoms/LogInput";
import { Formik } from "formik";
import * as Yup from "yup";
import LogSelect from "../../../../components/atoms/LogSelect";
import LogTextArea from "../../../../components/atoms/LogTextArea";
import {
  getTripAPI,
  postSendPostulation,
  getPaymentTypeAPI,
  getTUnitsByUserAPI,
} from "../../../../api/modules";
import { errorToast, successToast } from "../../../../services/toastService";
import LogSelectMultiCustom from "../../../../components/atoms/LogSelectMultiCustom";
import LogInputCurrency from "../../../../components/atoms/LogInputCurrency";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import NewTomador from "../Dashboard/Tomador/NewTomador";
import { AiOutlineClose } from "react-icons/ai";
import CloseButton from "../../../../components/organisms/close-button/CloseButton";
import { numberRegex } from "../../../../utils/helpers";
const TAXES_LABEL = process.env.REACT_APP_TAXES_LABEL;

const ModalLogStyled = styled(Modal)`
  opacity: 1;
  .modal-content {
    border-radius: 15px !important;
    padding: 20px;
  }
  p {
    color: ${COLOR_GRAY_TEXT};
  }
`;

const ContentModal = styled.div`
  text-align: left;
`;

const ContentFooterCenter = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const ModalPostulation = ({
  idTrip,
  isPostulate,
  onClosePostulation,
  modalType,
  data,
}) => {
  const [isShow, setShowModal] = useState(false);
  const [pricePerTruck, sePricePerTruck] = useState(0);
  const [truckCharge, setTruckCharge] = useState(0);
  const [paymentTypeAPI, setPaymentTypeAPI] = useState([]);
  const [priceType, setPriceType] = useState("1");
  const [trucksAPI, setTrucksAPI] = useState([]);
  const [firstLogin, setFirstLogin] = useState(null);
  const [trucksNumber, setTrucksNumber] = useState(0);
  const [unitTruckCharge, setUnitTruckCharge] = useState("");
  const spinner = useSelector((state) => state.appReducer.isLoading);
  const { _id } = useSelector((state) => state.authApp.user);
  const history = useHistory();

  useEffect(() => {
    if (idTrip) getFetchTrucks(idTrip);
    setShowModal(isPostulate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPostulate]);

  const getFetchTrucks = async (id) => {
    const { trucks } = await getTUnitsByUserAPI(_id);
    if (trucks) {
      /* const trucksSelect = trucks.map((l) => ({
        _id: l._id,
        label: l.domain,
        truck_type: l.truck_type,
        value: l._id,
      })).filter((t) => t.truck_type === '1');
      setTrucksAPI(trucksSelect); */
      setTrucksAPI(trucks);
    } else {
      setFirstLogin(true);
      return;
    }

    let paymentTypeFormatted;
    // ------------ getPaymentTypeAPI
    const respPaymentTypeAPI = await getPaymentTypeAPI();
    const { paymentType } = respPaymentTypeAPI;
    try {
      paymentTypeFormatted = paymentType.map((l) => ({
        _id: l._id,
        label: l.payment_type_name,
        value: l._id,
      }));
      setPaymentTypeAPI(paymentTypeFormatted);
    } catch (error) {
      setPaymentTypeAPI([]);
    }

    const responsetrip = await getTripAPI(id);
    const { trip } = responsetrip.data;
    const {
      truck_charge,
      price_type,
      trucks_number,
      trucks_number_accepted,
      unit_truck_charge,
    } = trip;

    setUnitTruckCharge(unit_truck_charge);

    setTrucksNumber(trucks_number - trucks_number_accepted);
    setTruckCharge(truck_charge);
    setPriceType(price_type);
    setFirstLogin(false);
  };

  const handleClose = (payload) => {
    setShowModal(false);
    onClosePostulation(payload);
  };

  const handlePriceWeightByTruck = (weightPrice = 0, Qty = 0) => {
    if (
      isNaN(weightPrice) ||
      isNaN(Qty) ||
      typeof weightPrice !== "number" ||
      typeof Qty !== "number"
    )
      return;
    sePricePerTruck(weightPrice * Qty);
  };

  let PostulationSchema = null;
  if (modalType === "1") {
    // id_truck: Yup.array().min(1, "Debes seleccionar al menos a una unidad").max(trucksNumber, `Debes seleccionar máximo ${trucksNumber} unidades`)
    //     .required("Unidad/es es obligatorio"),
    PostulationSchema = Yup.object().shape({
      number_trucks: Yup.number()
        .min(1, "Debes seleccionar al menos a una unidad")
        .max(trucksNumber, `Debes seleccionar máximo ${trucksNumber} unidades`)
        .required("Unidad/es es obligatorio"),
      price_ton_postulation: Yup.string()
        .required("Precio por tonelada es obligatorio")
        .test("is-valid-number", "Valor invalido", (value) => {
          if (!value) return false; // Si el valor está vacío, no pasa la validación

          // Verificar si es un número entero o un número con dos decimales
          return /^\d+(\.\d{1,2})?$/.test(value);
        }),
      /*  payment_type_postulation: Yup.string().required(
        "Tipo de pago es obligatorio"
      ), */
      payment_terms_days_postulation: Yup.number().required(
        "Tipo de pago es obligatorio"
      ),
      requirements_conditions: Yup.string()
        .test(
          "max-two-numbers",
          "Solo se permiten hasta dos números o palabras relacionadas con números",
          (value) => {
            if (!value) return true;
            const matches = value.match(numberRegex);
            return !matches || matches.length <= 2;
          }
        )
        .max(300, "Máximo 300 caracteres"),
    });
  } else {
    // id_truck: Yup.array().min(1, "Debes seleccionar al menos a una unidad").max(trucksNumber, `Debes seleccionar máximo ${trucksNumber} unidades`)
    //   .required("Unidad/es es obligatorio"),
    PostulationSchema = Yup.object().shape({
      number_trucks: Yup.number()
        .min(1, "Debes seleccionar al menos a una unidad")
        .max(trucksNumber, `Debes seleccionar máximo ${trucksNumber} unidades`)
        .required("Unidad/es es obligatorio"),
    });
  }
  //

  const handleSubmitAPI = async (body, resetForm) => {
    const bodyPayload = {
      ...body,
      id_trip: idTrip,
      id_truck: null,
      requirements_conditions: body.requirements_conditions || "",
    };

    const response = await postSendPostulation(bodyPayload);
    const { data, msg } = response;

    if (data) {
      successToast(msg);
      handleClose("success");
      resetForm();
      history.push("/app/my-travels-tc");
    } else {
      errorToast(msg || "Algo ha pasado");
    }
  };
  return firstLogin === null ? null : firstLogin ? (
    <ModalLogStyled
      show={isShow}
      size={"lg"}
      onHide={handleClose}
      className="modal-exit"
    >
      <CloseButton handleClose={handleClose} />

      <Modal.Body>
        <NewTomador searchTrip handleClose={handleClose} />
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </ModalLogStyled>
  ) : (
    <Formik
      enableReinitialize
      initialValues={{
        price_ton_postulation: data?.price_ton_postulation || "",
        payment_terms_days_postulation:
          data?.payment_terms_days_postulation || "",
      }}
      validationSchema={PostulationSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        handleSubmitAPI(values, resetForm);
        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleReset,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <ModalLogStyled
          show={isShow}
          onHide={handleClose}
          className="modal-exit"
        >
          <CloseButton handleClose={handleClose} />
          <Modal.Body>
            <ContentModal>
              <h4 className="font-weight-bold text-primary h5">
                Precios y condiciones
              </h4>
              <hr />
            </ContentModal>
            {modalType === "2" && (
              <Row>
                <Col md="12">
                  <Form.Group>
                    <label className="black-primary font-weight-bold">
                      Cuantos camiones tienes para esta carga?
                    </label>

                    <LogInput
                      placeholder="Unidad"
                      className="mt-1"
                      type="text"
                      name="number_trucks"
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (e.target.value === "" || re.test(e.target.value)) {
                          const number = parseFloat(e.target.value || 0);
                          /* setFieldValue("number_trucks", number); */
                          handleChange({
                            target: {
                              name: "number_trucks",
                              value: number,
                            },
                          });
                        }
                      }}
                      onBlur={handleBlur}
                      disabled={trucksAPI.length === 0}
                      errors={errors}
                      value={values.number_trucks}
                      maxLength={3}
                    />
                  </Form.Group>
                </Col>
              </Row>
            )}

            {modalType === "1" && (
              <div>
                <Row>
                  <Col md="12">
                    <Form.Group>
                      <label className="black-primary font-weight-bold">
                        Cuantos camiones tienes para esta carga?
                      </label>

                      <LogInput
                        placeholder="Unidad"
                        className="mt-1"
                        type="text"
                        name="number_trucks"
                        onChange={(e) => {
                          const re = /^[0-9\b]+$/;
                          if (
                            e.target.value === "" ||
                            re.test(e.target.value)
                          ) {
                            const number = parseFloat(e.target.value || 0);
                            /* setFieldValue("number_trucks", number); */
                            handleChange({
                              target: {
                                name: "number_trucks",
                                value: number,
                              },
                            });
                          }
                        }}
                        onBlur={handleBlur}
                        disabled={trucksAPI.length === 0}
                        errors={errors}
                        value={values.number_trucks}
                        maxLength={3}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <Form.Group>
                      <label className="black-primary font-weight-bold">
                        {unitTruckCharge
                          ? "Precio por " + unitTruckCharge
                          : "Precio por tonelada"}
                      </label>

                      <LogInputCurrency
                        className="number-font"
                        placeholder="AR$"
                        type="text"
                        decimalScale={2}
                        name="price_ton_postulation"
                        prefix={"$ "}
                        onValueChange={(values) => {
                          const { value } = values;
                          setFieldValue("price_ton_postulation", value);
                          const number = parseFloat(value);
                          if (!isNaN(number)) {
                            handlePriceWeightByTruck(
                              number,
                              parseFloat(truckCharge)
                            );
                          } else {
                            handlePriceWeightByTruck(
                              0,
                              parseFloat(truckCharge)
                            );
                          }
                        }}
                        onBlur={handleBlur}
                        errors={errors}
                        value={values.price_ton_postulation}
                      />
                    </Form.Group>
                  </Col>
                  <Col md="6">
                    <Form.Group>
                      <label className="black-primary font-weight-bold">
                        Precio por camión sin IVA
                      </label>
                      <LogInputCurrency
                        className="number-font"
                        placeholder="AR$"
                        type="text"
                        name="pricePerTruck"
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        decimalScale={2}
                        prefix={"$ "}
                        /* onChange={handleChange} */
                        onBlur={handleBlur}
                        errors={errors}
                        value={pricePerTruck}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  {/* <Col md="6">
                    <Form.Group>
                      <label className="font-weight-bold text-black">
                        Forma de pago
                      </label>
                      <LogSelect
                        name="payment_type_postulation"
                        placeholder="Forma de pago"
                        items={paymentTypeAPI}
                        onChange={(v) => {
                          setFieldValue("payment_type_postulation", v);
                        }}
                        onBlur={handleBlur}
                        errors={errors}
                        value={values.payment_type_postulation}
                      />
                    </Form.Group>
                  </Col> */}
                  <Col md="6">
                    <Form.Group>
                      <label className="font-weight-bold text-black">
                        Plazos de pagos en días
                      </label>
                      <LogInput
                        className="number-font"
                        placeholder="Ingresá un número"
                        type="text"
                        name="payment_terms_days_postulation"
                        onChange={(e) => {
                          const re = /^[0-9\b]+$/;
                          if (
                            e.target.value === "" ||
                            re.test(e.target.value)
                          ) {
                            const number = parseFloat(e.target.value || 0);
                            /* setFieldValue(
                              "payment_terms_days_postulation",
                              number
                            ); */
                            handleChange({
                              target: {
                                name: "payment_terms_days_postulation",
                                value: number,
                              },
                            });
                          }
                        }}
                        onBlur={handleBlur}
                        errors={errors}
                        value={values.payment_terms_days_postulation}
                        maxLength={2}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label className="font-weight-bold text-black">
                      Requisitos y condiciones
                    </label>
                    <div>
                      <LogTextArea
                        className="mb-2 number-font"
                        name="requirements_conditions"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errors={errors}
                        value={values.requirements_conditions}
                        maxLength={400}
                      />
                    </div>
                  </Col>
                </Row>
                <h6 className=" h6 font-weight-bold font-italic m-0">
                  <small style={{ color: "inherit" }}>
                    {TAXES_LABEL || "Montos netos sin impuestos"}
                  </small>
                </h6>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <ContentFooterCenter>
              <LogButton
                color="primary"
                onClick={handleSubmit}
                title={"Enviar oferta"}
                border="curve"
                /* block */
                className="mr-4"
              ></LogButton>
              <LogButton
                color="secondary"
                onClick={() => {
                  sePricePerTruck(0);
                  handleReset();
                  handleClose();
                }}
                title={"Cancelar"}
                border="curve"
                /* block */
              ></LogButton>
            </ContentFooterCenter>
          </Modal.Footer>
        </ModalLogStyled>
      )}
    </Formik>
  );
};

export default ModalPostulation;
