/* eslint-disable no-unused-vars */
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import {
  postTUnitAPI,
  putUnitByIdAPI,
  delTUnitByIdAPI,
  getUnitByIDAPI,
  getTruckTypeAPI,
  getTruckCharacteristicByTruckIdAPI,
  getUploadTruckPhoto,
  putUploadTruckPhoto,
} from "../../../../../api/modules";

import { BiTrash } from "react-icons/bi";

// react-bootstrap components
import {
  Card,
  Form,
  Container,
  Row,
  Col,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { errorToast, successToast } from "../../../../../services/toastService";
import LogInput from "../../../../../components/atoms/LogInput";
import LogButton from "../../../../../components/atoms/LogButton";
import { useHistory, useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import ContentHeader from "../../../components/molecules/ContentHeader";
import ModalQuestion from "../../../../../components/organisms/modal-question/ModalQuestion";
import ModalInfo from "../../../../../components/organisms/modal-info/ModalInfo";
import queryString from "query-string";
import LogSelect from "../../../../../components/atoms/LogSelect";
import DropZone from "../../../../../components/organisms/dropzone/DropZone";
import { units } from "../../../../../constants/staticSelectItems";
import LogSelectMulti from "../../../../../components/atoms/LogSelectMultiCustom";
import { useSelector, useDispatch } from "react-redux";
import {
  setLoadingSpinerOff,
  setLoadingSpinerOn,
} from "../../../../../redux/actions/app";
import LogDatePicker from "../../../../../components/atoms/LogDatePicker";

const BtnInvisible = styled(Button)`
  background: none;
  border: none;
  &:hover {
    opacity: 0.7;
    background: none !important;
    color: black !important;
    border: none;
  }
  &:active {
    opacity: 0.7;
    background: none !important;
    color: black !important;
    border: none;
  }
  &:focus {
    opacity: 0.7;
    background: none !important;
    color: black !important;
    border: none;
  }
`;
const RowDeleteStyled = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  right: 15px;
  justify-content: flex-end;
  position: absolute;
  top: 10px;
`;
const StyledLogButton = styled(LogButton)`
  width: 100%;
  max-width: 200px;
`;

const CardLogBootstrapStyled = styled(Card)`
  padding: 10px;
  border-radius: 15px;
`;

function MyUnit() {
  const [modalExit, setModalExit] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [isEditing, setIsEditing] = useState(true);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [unitType, setUnitType] = useState(null);
  const [truckTypes, setTruckTypes] = useState([]);
  const [truckCharac, setTruckCharac] = useState([]);
  const params = useParams();
  const [entity, setEntity] = useState({
    id_truckType: {},
  });
  const [resetDropZone, setResetDropZone] = useState(false);
  const [showInFrontPhoto, setShowInFrontPhoto] = useState(true);
  const [inFrontPhoto, setInFrontPhoto] = useState(null);
  const [showBackPhoto, setShowBackPhoto] = useState(true);
  const [backPhoto, setBackPhoto] = useState(null);
  const [insurancePhoto, setInsurancePhoto] = useState(null);
  const [inspectionPhoto, setInspectionPhoto] = useState(null);
  const [showInsurancePhoto, setShowInsurancePhoto] = useState(true);
  const [showInspectionPhoto, setShowInspectionPhoto] = useState(true);
  const [fileErrors, setTileErrors] = useState({
    inFrontError: false,
    backError: false,
    insuranceError: false,
    inspectioncError: false,
  });

  let ProfileSchema = null;
  if (unitType === "semi") {
    ProfileSchema = Yup.object().shape(
      {
        domain: Yup.string().required(
          `Dominio del ${
            unitType === "semi" ? "acoplado" : "camion"
          } es obligatorio`
        ),
        brand: Yup.string(),
        model: Yup.string(),
        max_cargo: Yup.string().when("unit", (unit) => {
          if (unit)
            return Yup.string().required(
              "Cantidad de carga máxima es obligatorio"
            );
        }),
        unit: Yup.string().when("max_cargo", (max_cargo) => {
          if (max_cargo !== 0 && max_cargo === "")
            return Yup.string().required("Unidad de medida es obligatorio");
        }),
        id_truckType: Yup.string().required("El campo es requerido"),
        truck_features: Yup.array(),
      },
      [["max_cargo", "unit"]]
    );
  } else {
    ProfileSchema = Yup.object().shape(
      {
        domain: Yup.string().required(
          `Dominio del ${
            unitType === "semi" ? "acoplado" : "camion"
          } es obligatorio`
        ),
        brand: Yup.string(),
        model: Yup.string(),
        max_cargo: Yup.string().when("unit", (unit) => {
          if (unit)
            return Yup.string().required(
              "Cantidad de carga máxima es obligatorio"
            );
        }),
        unit: Yup.string().when("max_cargo", (max_cargo) => {
          if (max_cargo !== 0 && max_cargo === "")
            return Yup.string().required("Unidad de medida es obligatorio");
        }),
      },
      [["max_cargo", "unit"]]
    );
  }
  useEffect(() => {
    if (entity?._id) setIsEditing(false);
  }, [entity]);

  useEffect(() => {
    const { id } = params;
    if (id) {
      fetchApi(id);
    }
    const { type } = queryString.parse(location.search);
    setUnitType(type);
    const getFetchData = async () => {
      // ------------ truckTypes
      const respTruckTypeAPI = await getTruckTypeAPI();
      const { truckTypes } = respTruckTypeAPI;
      try {
        const truckTypesFormatted = truckTypes.map((l) => ({
          _id: l._id,
          label: l.truck_type_name,
          value: l._id,
        }));
        setTruckTypes(truckTypesFormatted);
      } catch (error) {
        setTruckTypes([]);
      }
    };

    getFetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchApi = async (id) => {
    const response = await getUnitByIDAPI(id);
    const { truck } = response;
    const { type } = queryString.parse(location.search);
    // getPhotoOne(id);
    setEntity(truck);

    if (type === "semi") {
      getFetchTruckCharsSelect(truck.id_truckType._id);
    }
  };

  const handleDeleteItem = async (id) => {
    const resp = await delTUnitByIdAPI(id);
    const { msg } = resp;
    if (msg) {
      errorToast(msg);
    } else {
      successToast("Unidad eliminada exitosamente");
      history.push("/app/my-units");
    }
  };

  const handleSuccessModal = async (response) => {
    if (response) history.push("/app/my-units");
  };
  const handleCloseExitModal = async (response) => {
    if (response) {
      await handleDeleteItem(entity._id);
      setModalExit(false);
    } else {
      setModalExit(false);
    }
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const RowDelete = () => {
    return entity._id ? (
      <RowDeleteStyled>
        <OverlayTrigger
          key="delete"
          overlay={<Tooltip id={`delete`}>Eliminar</Tooltip>}
        >
          <BtnInvisible
            onClick={(event) => {
              event.stopPropagation();
              setModalExit(true);
            }}
          >
            <BiTrash />{" "}
          </BtnInvisible>
        </OverlayTrigger>
      </RowDeleteStyled>
    ) : (
      ""
    );
  };

  const getStringType = () => {
    return unitType === "semi" ? "Acoplado" : "Camión";
  };

  const getFetchTruckCharsSelect = async (value) => {
    setTruckCharac([]);
    let truckCharsArr = [];
    const resp = await getTruckCharacteristicByTruckIdAPI(value);
    const { truckChars } = resp;
    for (const truckC of truckChars) {
      truckCharsArr.push(truckC);
    }
    const truckCharsFormatted = truckCharsArr.map((l) => ({
      _id: l._id,
      label: l.truck_characteristic_name,
      value: l._id,
    }));
    setTruckCharac(truckCharsFormatted);
  };

  const getCharacterTruck = (truckCharsArr) => {
    try {
      const truckCharsFormatted = truckCharsArr.map((l) => l._id);
      return truckCharsFormatted;
    } catch (error) {
      return [];
    }
  };

  const handleSubmitAPI = async (values, resetForm) => {
    let body = null;
    body = {
      ...values,
      truck_type: unitType === "truck" ? "1" : "2",
    };

    if (body.max_cargo === 0) {
      delete body["max_cargo"];
    }

    if (unitType === "truck") {
      delete body["id_truckType"];
      delete body["truck_features"];
    }

    let response;
    if (!showInFrontPhoto) body = { ...body, front_photo: "" };
    if (!showBackPhoto) body = { ...body, back_photo: "" };
    try {
      dispatch(setLoadingSpinerOn());
      if (inFrontPhoto?.file) {
        const data = new FormData();
        data.append("file", inFrontPhoto?.file);
        data.append("upload_preset", "ceqdn88c");
        const res = await fetch(
          "https://api.cloudinary.com/v1_1/dcbqcvers/image/upload",
          {
            method: "POST",
            body: data,
          }
        );
        const file = await res.json();
        body = { ...body, front_photo: file.secure_url };
      }
      if (backPhoto?.file) {
        const data = new FormData();
        data.append("file", backPhoto?.file);
        data.append("upload_preset", "ceqdn88c");
        const res = await fetch(
          "https://api.cloudinary.com/v1_1/dcbqcvers/image/upload",
          {
            method: "POST",
            body: data,
          }
        );
        const file = await res.json();
        body = { ...body, back_photo: file.secure_url };
      }
      if (insurancePhoto?.file) {
        const data = new FormData();
        data.append("file", insurancePhoto?.file);
        data.append("upload_preset", "ceqdn88c");
        const res = await fetch(
          "https://api.cloudinary.com/v1_1/dcbqcvers/image/upload",
          {
            method: "POST",
            body: data,
          }
        );
        const file = await res.json();
        body = { ...body, insurance_photo: file.secure_url };
      }
      if (inspectionPhoto?.file) {
        const data = new FormData();
        data.append("file", inspectionPhoto?.file);
        data.append("upload_preset", "ceqdn88c");
        const res = await fetch(
          "https://api.cloudinary.com/v1_1/dcbqcvers/image/upload",
          {
            method: "POST",
            body: data,
          }
        );
        const file = await res.json();
        body = { ...body, inspection_photo: file.secure_url };
      }
    } catch (error) {
      console.error("MYUNITS Fotos a cloudinary", error);
    } finally {
      dispatch(setLoadingSpinerOff());
    }

    if (entity._id) {
      response = await putUnitByIdAPI(entity._id, body);
    } else {
      response = await postTUnitAPI(body);
    }
    scrollToTop();
    const { msg } = response;
    if (msg) {
      errorToast(msg);
      if (entity._id) resetForm();
    } else {
      if (entity._id) {
        successToast("Unidad editada con éxito");
        history.push("/app/my-units");
        return;
      } else {
        setSuccessModal(true);
      }
    }
  };

  return (
    <>
      <Container fluid>
        <Formik
          enableReinitialize
          initialValues={{
            domain: entity.domain || "",
            brand: entity.brand || "",
            model: entity.model || "",
            max_cargo: entity.max_cargo || "",
            unit: entity.unit || "",
            id_truckType: entity?.id_truckType?._id || "",
            truck_features: getCharacterTruck(entity.truck_features) || [],
            front_photo: entity?.front_photo || "",
            back_photo: entity?.back_photo || "",
            insurance_photo: entity?.insurance_photo || "",
            inspection_photo: entity?.inspection_photo || "",
            insurance_date: entity?.insurance_date || null,
            inspection_date: entity?.inspection_date || null,
          }}
          validationSchema={ProfileSchema}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            handleSubmitAPI(values, resetForm);
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            dirty,
            resetForm,
          }) => (
            <Row>
              <ContentHeader
                className={`ml-3`}
                title={
                  entity._id
                    ? `Editá un nuevo ${getStringType().toLowerCase()}`
                    : `Registrá un nuevo ${getStringType().toLowerCase()}`
                }
              />
              <Col
                md="12"
                onClick={() => {
                  if (entity?._id && !isEditing) {
                    successToast(
                      "Para realizar cambios, toca 'Editar' al final del formulario."
                    );
                  }
                }}
              >
                <CardLogBootstrapStyled className="shadow-4dp pt-3">
                  <CardLogBootstrapStyled.Body>
                    <RowDelete />
                    <Row>
                      <Col md="6">
                        <Form.Group>
                          <label className="text-primary font-weight-bold">
                            Ingresá el dominio de tu{" "}
                            {getStringType().toLowerCase()}
                          </label>
                          <LogInput
                            readOnly={!isEditing}
                            placeholder="Ingresá el dominio"
                            type="text"
                            name="domain"
                            onChange={(e) => {
                              const { value } = e.target;
                              setFieldValue(
                                "domain",
                                value ? value.toUpperCase() : ""
                              );
                            }}
                            onBlur={handleBlur}
                            errors={errors}
                            value={values.domain}
                          />
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Form.Group>
                          <label className="text-primary font-weight-bold">
                            Ingresá la cantidad de carga maxima de tu{" "}
                            {getStringType().toLowerCase()}{" "}
                            <span className="text-infoLight">(opcional)</span>
                          </label>
                          <Row>
                            <Col md="6">
                              <LogInput
                                readOnly={!isEditing}
                                className="number-font"
                                placeholder="Ingresá un número"
                                type="text"
                                name="max_cargo"
                                onChange={(e) => {
                                  const { value } = e.target;
                                  const re = /^[0-9\b]+$/;
                                  if (value === "" || re.test(value)) {
                                    const number = parseFloat(value || 0);
                                    setFieldValue("max_cargo", number);
                                  } else {
                                    setFieldValue("max_cargo", 0);
                                  }
                                }}
                                onBlur={handleBlur}
                                errors={errors}
                                value={values.max_cargo}
                              />
                            </Col>
                            <Col md="6">
                              {" "}
                              <LogSelect
                                readOnly={!isEditing}
                                cleanable={false}
                                placeholder="Unidad de medida"
                                name="unit"
                                items={units}
                                onChange={(v) => {
                                  setFieldValue("unit", v);
                                }}
                                onBlur={handleBlur}
                                errors={errors}
                                value={values.unit}
                              />
                            </Col>
                          </Row>
                        </Form.Group>
                      </Col>
                    </Row>
                  </CardLogBootstrapStyled.Body>
                </CardLogBootstrapStyled>
                <CardLogBootstrapStyled className="shadow-4dp pt-3 mt-4">
                  <CardLogBootstrapStyled.Body>
                    <Row>
                      <Col md="6">
                        <label className="text-primary font-weight-bold">
                          ¿ Qué modelo es tu {getStringType().toLowerCase()}?{" "}
                          <span className="text-infoLight">(opcional)</span>
                        </label>
                        <LogInput
                          readOnly={!isEditing}
                          placeholder="Ingresá el modelo"
                          type="text"
                          name="model"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          errors={errors}
                          value={values.model}
                        />
                      </Col>
                      <Col md="6">
                        <label className="text-primary font-weight-bold">
                          Marca &nbsp;
                          <span className="text-infoLight">(opcional)</span>
                        </label>
                        <LogInput
                          readOnly={!isEditing}
                          placeholder="Ingresá la marca"
                          type="text"
                          name="brand"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          errors={errors}
                          value={values.brand}
                        />
                      </Col>
                    </Row>
                  </CardLogBootstrapStyled.Body>
                </CardLogBootstrapStyled>
                {unitType === "semi" && (
                  <CardLogBootstrapStyled className="shadow-4dp pt-3 mt-4">
                    <CardLogBootstrapStyled.Body>
                      <Row>
                        <Col md="6">
                          <label className="text-primary font-weight-bold">
                            Tipo de camión &nbsp;
                          </label>
                          <LogSelect
                            readOnly={!isEditing}
                            cleanable={false}
                            placeholder="Tipo de camión"
                            name="id_truckType"
                            items={truckTypes}
                            onChange={async (value) => {
                              getFetchTruckCharsSelect(value);
                              setFieldValue("id_truckType", value);
                            }}
                            onBlur={handleBlur}
                            errors={errors}
                            value={values.id_truckType}
                          />
                        </Col>
                        <Col md="6">
                          <label className="text-primary font-weight-bold">
                            Seleccioná las características{" "}
                            <span className="text-infoLight">(opcional)</span>
                          </label>
                          <LogSelectMulti
                            placeholder="Características"
                            className="mt-1"
                            name="truck_features"
                            items={truckCharac}
                            onChange={(v) => {
                              setFieldValue("truck_features", v);
                            }}
                            onBlur={handleBlur}
                            readOnly={truckCharac.length === 0 || !isEditing}
                            errors={errors}
                            value={values.truck_features}
                          />
                        </Col>
                      </Row>
                    </CardLogBootstrapStyled.Body>
                  </CardLogBootstrapStyled>
                )}
                <CardLogBootstrapStyled className="shadow-4dp pt-3 mt-4">
                  <CardLogBootstrapStyled.Header className="pl-0 pt-4 pr-0 ml-4 mr-4 mb-0">
                    <label className="text-primary font-weight-bold mb-0">
                      Tarjeta verde
                    </label>
                  </CardLogBootstrapStyled.Header>
                  <CardLogBootstrapStyled.Body>
                    <Row>
                      <Col md="6">
                        <label className="back-primary font-weight-bold">
                          Foto Tarjeta Verde (Frente)
                        </label>
                        <DropZone
                          reset={resetDropZone}
                          readOnly={!isEditing}
                          image={showInFrontPhoto ? entity?.front_photo : null}
                          uploadFile={(file) => {
                            setInFrontPhoto({
                              file: file,
                              url: file,
                            });
                            setFieldValue("front_photo", "newPhoto");
                          }}
                          errors={fileErrors.inFrontError}
                          removeFile={() => {
                            setFieldValue("front_photo", "");
                            setInFrontPhoto(null);
                            setShowInFrontPhoto(null);
                          }}
                          placeholder="Subir foto de frente"
                        />
                      </Col>
                      <Col md="6">
                        <label className="back-primary font-weight-bold">
                          Foto Tarjeta Verde (Dorso)
                        </label>
                        <DropZone
                          reset={resetDropZone}
                          readOnly={!isEditing}
                          image={showBackPhoto ? entity?.back_photo : null}
                          uploadFile={(file) => {
                            setBackPhoto({
                              file: file,
                              url: file,
                            });
                            setFieldValue("back_photo", "New Photo");
                          }}
                          errors={fileErrors.backError}
                          removeFile={() => {
                            setFieldValue("back_photo", "");
                            setBackPhoto(null);
                            setShowBackPhoto(null);
                          }}
                          placeholder="Subir foto de dorso"
                        />
                      </Col>
                    </Row>
                  </CardLogBootstrapStyled.Body>
                </CardLogBootstrapStyled>
                <CardLogBootstrapStyled className="shadow-4dp pt-3 mt-4">
                  <CardLogBootstrapStyled.Header className="pl-0 pt-4 pr-0 ml-4 mr-4 mb-0">
                    <label className="text-primary font-weight-bold mb-0">
                      Documentacion Adicional (opcional)
                    </label>
                  </CardLogBootstrapStyled.Header>
                  <CardLogBootstrapStyled.Body>
                    <Row>
                      <Col md="6">
                        <label className="back-primary font-weight-bold">
                          Tecnica
                        </label>
                        <DropZone
                          reset={resetDropZone}
                          readOnly={!isEditing}
                          image={
                            showInspectionPhoto
                              ? entity?.inspection_photo
                              : null
                          }
                          uploadFile={(file) => {
                            setInspectionPhoto({
                              file: file,
                              url: file,
                            });
                            setFieldValue("inspection_photo", "New Photo");
                          }}
                          errors={fileErrors.inspectioncError}
                          removeFile={() => {
                            setFieldValue("inspection_photo", "");
                            setInspectionPhoto(null);
                            setShowInspectionPhoto(null);
                          }}
                          placeholder="Subir foto"
                        />
                        <LogDatePicker
                          cleanable
                          readOnly={!isEditing}
                          name="inspection_date"
                          placeholder="Vencimiento"
                          className="number-font mt-2"
                          onBlur={handleBlur}
                          errors={errors}
                          value={
                            values.inspection_date
                              ? new Date(values.inspection_date)
                              : null
                          }
                          onChange={(value) => {
                            setFieldValue("inspection_date", value);
                          }}
                        />
                      </Col>
                      <Col md="6">
                        <label className="back-primary font-weight-bold">
                          Seguro
                        </label>
                        <DropZone
                          reset={resetDropZone}
                          readOnly={!isEditing}
                          image={
                            showInsurancePhoto ? entity?.insurance_photo : null
                          }
                          uploadFile={(file) => {
                            setInsurancePhoto({
                              file: file,
                              url: file,
                            });
                            setFieldValue("insurance_photo", "New Photo");
                          }}
                          errors={fileErrors.insuranceError}
                          removeFile={() => {
                            setFieldValue("insurance_photo", "");
                            setInsurancePhoto(null);
                            setShowInsurancePhoto(null);
                          }}
                          placeholder="Subir foto"
                        />
                        <LogDatePicker
                          readOnly={!isEditing}
                          cleanable={true}
                          name="insurance_date"
                          placeholder="Vencimiento"
                          className="number-font mt-2"
                          onBlur={handleBlur}
                          errors={errors}
                          value={
                            values.insurance_date
                              ? new Date(values.insurance_date)
                              : null
                          }
                          onChange={(value) => {
                            setFieldValue("insurance_date", value);
                          }}
                        />
                      </Col>
                    </Row>
                  </CardLogBootstrapStyled.Body>
                </CardLogBootstrapStyled>
              </Col>
              <Row className="w-100 mx-1">
                {entity._id ? (
                  isEditing ? (
                    <>
                      <Col md={6} className=" text-center text-md-left">
                        <StyledLogButton
                          className="mt-4 "
                          color="secondary"
                          size="md"
                          title={"Cancelar"}
                          border="curve"
                          block
                          onClick={() => {
                            resetForm();
                            setIsEditing(false);
                            setResetDropZone(true);

                            setInFrontPhoto(null);
                            setShowInFrontPhoto(true);

                            setBackPhoto(null);
                            setShowBackPhoto(true);

                            setInsurancePhoto(null);
                            setShowInsurancePhoto(true);

                            setInspectionPhoto(null);
                            setShowInspectionPhoto(true);
                          }}
                        />
                      </Col>
                      <Col md={6} className="text-center text-md-right">
                        <StyledLogButton
                          className="mt-4  "
                          size="md"
                          title={"Guardar cambios"}
                          border="curve"
                          block
                          disabled={isSubmitting}
                          onClick={() => {
                            if (!dirty) {
                              errorToast(
                                "Primero introduzca cambios en el formulario"
                              );
                            } else {
                              handleSubmit();
                              setResetDropZone(false);
                            }
                          }}
                        />
                      </Col>
                    </>
                  ) : (
                    <Col>
                      <StyledLogButton
                        className="mt-4 "
                        size="md"
                        title={`${"Editar"} ${getStringType().toLowerCase()}`}
                        border="curve"
                        block
                        onClick={() => {
                          setResetDropZone(false);
                          setIsEditing(true);
                        }}
                      />
                    </Col>
                  )
                ) : (
                  <Col>
                    <StyledLogButton
                      className="mt-4 "
                      size="md"
                      title={`${
                        entity._id ? "Editar" : "Agregar"
                      } ${getStringType().toLowerCase()}`}
                      border="curve"
                      block
                      onClick={handleSubmit}
                    />
                  </Col>
                )}
              </Row>

              <div className="clearfix"></div>
              <ModalQuestion
                title={`Eliminar la unidad ${entity?.domain || ""}?`}
                subTitle=" "
                isShow={modalExit}
                handleClose={handleCloseExitModal}
              />
              <ModalInfo
                title={`Tu ${getStringType().toLowerCase()} se guardó exitosamente`}
                subTitle="Podés usar esta nueva unidad a la hora de llevar a cabo un nuevo viaje"
                isShow={successModal}
                handleClose={handleSuccessModal}
              />
            </Row>
          )}
        </Formik>
      </Container>
    </>
  );
}

export default MyUnit;
