import React, { useEffect } from "react";

export const apkUrl = process.env.REACT_APP_URL_APP;

const AppLink = () => {
  useEffect(() => {
    console.log("REDIRECT", apkUrl);
    window.location.href = apkUrl;
  }, []);

  return null;
};

export default AppLink;
