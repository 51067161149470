/* eslint-disable react-hooks/exhaustive-deps */
import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import {
  Card,
  Form,
  Container,
  Row,
  Col,
  Alert,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import LogInput from "../../../../components/atoms/LogInput";
import LogInputCurrency from "../../../../components/atoms/LogInputCurrency";
import LogButton from "../../../../components/atoms/LogButton";
import LogTextArea from "../../../../components/atoms/LogTextArea";
import LogRadioGroup from "../../../../components/atoms/LogRadioGroup";
import LogDatePicker from "../../../../components/atoms/LogDatePicker";
import LogTimerPicker from "../../../../components/atoms/LogTimerPicker";
import LogSelect from "../../../../components/atoms/LogSelect";
import LogSelectLocation from "../../../../components/atoms/LogSelectLocation";
import LogSelectMulti from "../../../../components/atoms/LogSelectMultiCustom";
import { units } from "../../../../constants/staticSelectItems";
import useGoogleMapsApi from "../../../../services/useGoogleMapsApi";
import {
  getChargeAPI,
  getProductsAPI,
  getTruckTypeAPI,
  getTruckCharacteristicByTruckIdAPI,
  getTruckAccesoryAPI,
  getPaymentTypeAPI,
  getLocationsAPIAll,
} from "../../../../api/modules";
import LogRadioGroupWrapper from "../../../../components/atoms/LogRadioGroupWrapper";
import ContentHeader from "../../components/molecules/ContentHeader";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import LogInputPhone from "../../../../components/atoms/LogInputPhone";
import ModalInfo from "../../../../components/organisms/modal-info/ModalInfo";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { errorToast } from "../../../../services/toastService";
import { numberRegex } from "../../../../utils/helpers";
const TAXES_LABEL = process.env.REACT_APP_TAXES_LABEL;

const TravelSchema = Yup.object().shape({
  origin_site: Yup.string().required("Lugar de origen es obligatorio"),
  destiny_site: Yup.string()
    .required("Lugar destino es obligatorio")
    .notOneOf(
      [Yup.ref("origin_site"), null],
      "No puedes seleccionar la misma localización"
    ),
  origin: Yup.string().required("Tipo de origen es obligatorio"),
  destiny: Yup.string().required("Tipo de destino es obligatorio"),
  withdraw_date: Yup.date()
    .required("Fecha de retiro es obligatorio")
    .nullable(),
  withdraw_turn: Yup.string().required("Turno de retiro es obligatorio"),
  delivery_date: Yup.date()
    .required("Fecha de retiro es obligatorio")
    .nullable()
    .when(["withdraw_date"], (withdraw_date, schema) => {
      if (withdraw_date) {
        return schema.min(
          new Date(withdraw_date),
          "La fecha de entrega debe ser igual o posterior a la de retiro."
        );
      }
    }),
  delivery_turn: Yup.string().required("Turno de entrega es obligatorio"),
  //
  product: Yup.string().nullable().required("Un producto es obligatorio"),
  charger_type: Yup.string().nullable().required("Tipo de carga obligatorio"),
  //
  trucks_number: Yup.number()
    .min(1, "Debes tener al menos 1 camión")
    .required("Número de camión obligatorio"),
  truck_type: Yup.array()
    .min(1, "Debes tener al menos un tipo de camión")
    .required("Tipo de camión obligatorio"),
  truck_charge: Yup.number()
    .min(1, "Cantidad de carga deber ser mayor a cero")
    .required("Cantidad de carga obligatorio"),
  unit_truck_charge: Yup.string().required("Unidad de carga obligatorio"),
  truck_features: Yup.array(),
  truck_accessories: Yup.array(),
  //
  price_per_ton: Yup.number()
    .min(1, "Debe ser mayor a cero")
    .required("Precio por tonelada obligatorio"),
  price_type: Yup.string().required("Tipo de precio obligatorio"),
  payment_terms_days: Yup.number()
    .min(1, "El plazo debe ser mayor a cero")
    .required("Plazo Efectivo de Pago obligatorio"),
  payment_type: Yup.string().nullable().required("Forma de pago obligatorio"),
  logis_number_dc: Yup.string()
    .matches(/^\+\d{12}$/, "Ej. +543811234456")
    .required("Teléfono Contacto Logística de Origen obligatorio"),
  requirements_conditions: Yup.string()
    .test(
      "max-two-numbers",
      "Solo se permiten hasta dos números o palabras relacionadas con números",
      (value) => {
        if (!value) return true;
        const matches = value.match(numberRegex);
        return !matches || matches.length <= 2;
      }
    )
    .max(300, "Máximo 300 caracteres"),
  additionl_destiny_indications: Yup.string()
    .test(
      "max-two-numbers",
      "Solo se permiten hasta dos números o palabras relacionadas con números",
      (value) => {
        if (!value) return true;
        const matches = value.match(numberRegex);
        return !matches || matches.length <= 2;
      }
    )
    .max(300, "Máximo 300 caracteres"),
  additional_origin_indications: Yup.string()
    .test(
      "max-two-numbers",
      "Solo se permiten hasta dos números o palabras relacionadas con números",
      (value) => {
        if (!value) return true;
        const matches = value.match(numberRegex);
        return !matches || matches.length <= 2;
      }
    )
    .max(300, "Máximo 300 caracteres"),
});

function NewTravel() {
  const mapRef = useRef(null);
  const history = useHistory();
  const locationUrl = useLocation();
  const [locationsAPI, setLocationsAPI] = useState([]);
  const [originMap, setOriginMap] = useState(null);
  const [destineMap, setDestineMap] = useState(null);
  const [kilometers, setKilometers] = useState(null);
  const [routesDetails, setRoutesDetails] = useState(null);
  const googleMapsApi = useGoogleMapsApi();
  const [routeError, setRouteError] = useState(false);
  const turns = [
    { label: "Mañana", value: "M" },
    { label: "Noche", value: "N" },
    { label: "Tarde", value: "A" },
  ];

  const [location, setLocation] = useState({
    lat: -38.4192641,
    lng: -63.5989206,
  });

  // selections
  const [chargeTypes, setChargeTypes] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [truckTypes, setTruckTypes] = useState([]);
  const [truckCharac, setTruckCharac] = useState([]);
  const [truckAccesories, setTruckAccesories] = useState([]);
  const [paymentTypeAPI, setPaymentTypeAPI] = useState([]);

  // form
  const [pricePerTruck, sePricePerTruck] = useState(0);

  // entity
  const [entity, setEntity] = useState({});

  // entity of labels supporting travel detail screen
  const [previewData, setPreviewData] = useState({
    charger_type: "",
    destiny_site: "",
    origin_site: "",
    product: "",
    truck_features: [],
    truck_type: [],
    truck_accessories: [],
    payment_type: "",
  });
  const convertUnit = (value) => {
    const convertedValue = {
      toneladas: "Tonelada",
      litros: "Litro",
      pallets: "Pallet",
      unidades: "Unidad",
    };
    return (
      convertedValue[value] || value.charAt(0).toUpperCase() + value.slice(1)
    );
  };

  useEffect(() => {
    const getFetchData = async () => {
      const resp = await getLocationsAPIAll();
      const { locations } = resp;
      let locationsFormatted;
      let cargoTypesFormatted;
      let productTypesFormatted;
      let truckTypesFormatted;
      let truckAccesoryFormatted;
      let paymentTypeFormatted;
      try {
        locationsFormatted = locations.map((l) => ({
          _id: l._id,
          label: l.site_name,
          address: l.address,
          value: l._id,
          lat: l.latitude,
          lng: l.longitude,
          additional_indications: l.additional_indications,
          origin: l.origin,
        }));
        setLocationsAPI(locationsFormatted);
      } catch (error) {
        setLocationsAPI([]);
      }
      // ------------ Charges
      const respCharge = await getChargeAPI();
      const { cargoTypes } = respCharge;
      try {
        cargoTypesFormatted = cargoTypes.map((l) => ({
          _id: l._id,
          label: l.cargo_type_name,
          value: l._id,
        }));
        setChargeTypes(cargoTypesFormatted);
      } catch (error) {
        setChargeTypes([]);
      }
      // ------------ getProductsAPI
      const respProducts = await getProductsAPI();
      const { productTypes } = respProducts;
      try {
        productTypesFormatted = productTypes.map((l) => ({
          _id: l._id,
          label: l.product_type_name,
          value: l._id,
        }));
        setProductTypes(productTypesFormatted);
      } catch (error) {
        setProductTypes([]);
      }
      // ------------ truckTypes
      const respTruckTypeAPI = await getTruckTypeAPI();
      const { truckTypes } = respTruckTypeAPI;
      try {
        truckTypesFormatted = truckTypes.map((l) => ({
          _id: l._id,
          label: l.truck_type_name,
          value: l._id,
        }));
        setTruckTypes(truckTypesFormatted);
      } catch (error) {
        setTruckTypes([]);
      }
      // ------------ getTruckAccesoryAPI
      const respTruckAccesoryAPI = await getTruckAccesoryAPI();
      const { truckAccesories } = respTruckAccesoryAPI;
      try {
        truckAccesoryFormatted = truckAccesories.map((l) => ({
          _id: l._id,
          label: l.accesory_name,
          value: l._id,
        }));
        setTruckAccesories(truckAccesoryFormatted);
      } catch (error) {
        setTruckAccesories([]);
      }
      // ------------ getPaymentTypeAPI
      const respPaymentTypeAPI = await getPaymentTypeAPI();
      const { paymentType } = respPaymentTypeAPI;
      try {
        paymentTypeFormatted = paymentType.map((l) => ({
          _id: l._id,
          label: l.payment_type_name,
          value: l._id,
        }));
        setPaymentTypeAPI(paymentTypeFormatted);
      } catch (error) {
        setPaymentTypeAPI([]);
      }

      /* const { retrieve_origin, retrieve_destine } = queryString.parse(
        locationUrl.search
      );
      console.log({ retrieve_origin, retrieve_destine }); */
      /* try {
        const indexTimer = setTimeout(() => {
          if (retrieve_origin) {
            const { lat, lng } = locationsFormatted?.find(
              (l) => l._id === retrieve_origin
            );
            console.log({ lat, lng });
            setOriginMap({ lat, lng });
            if (destineMap) calculateAndDisplayRoute({ lat, lng }, destineMap);
          }

          if (retrieve_destine) {
            const { lat, lng } = locationsFormatted?.find(
              (l) => l._id === retrieve_destine
            );
            console.log({ lat, lng });
            setDestineMap({ lat, lng });
            if (originMap) calculateAndDisplayRoute({ lat, lng }, originMap);
          }
          clearTimeout(indexTimer);
        }, 2000);
      } catch (error) {} */

      try {
        const { preview } = queryString.parse(locationUrl.search);
        const { previewEntity, previewLabels } = locationUrl.state;
        if (preview && previewEntity.origin) {
          onLoadEntity(previewEntity, locationsFormatted, previewLabels);
        }
      } catch (error) {}
    };
    getFetchData();
  }, []);
  const { retrieve_origin, retrieve_destine } = queryString.parse(
    locationUrl.search
  );
  useEffect(() => {
    if (locationsAPI?.length) {
      if (retrieve_destine) {
        const destine = locationsAPI.find((l) => l._id === retrieve_destine);
        if (destine) {
          const { lat, lng, label } = destine;
          setDestineMap({ lat, lng });
          setPreviewData((prev) => {
            return {
              ...prev,
              destiny_site: label,
            };
          });
        }
      }
      if (retrieve_origin) {
        const origin = locationsAPI.find((l) => l._id === retrieve_origin);
        if (origin) {
          const { lat, lng, label } = origin;
          setOriginMap({ lat, lng });
          setPreviewData((prev) => {
            return {
              ...prev,
              origin_site: label,
            };
          });
        }
      }
    }
  }, [retrieve_origin, retrieve_destine, locationsAPI]);

  useEffect(() => {
    if (destineMap && originMap) {
      calculateAndDisplayRoute(originMap, destineMap);
    }
  }, [destineMap, originMap]);

  const onLoadEntity = async (body, locations, previewLabels) => {
    setEntity({ ...body });
    getFetchTruckCharsSelect(body.truck_type);

    try {
      let originData;
      let destineData;
      if (body.origin_site) {
        const { lat, lng } = locations.find((l) => l._id === body.origin_site);
        setOriginMap({ lat, lng });
        originData = { lat, lng };
      }

      if (body.destiny_site) {
        const { lat, lng } = locations.find((l) => l._id === body.destiny_site);
        setDestineMap({ lat, lng });
        destineData = { lat, lng };
      }
      handlePriceWeightByTruck(body.price_per_ton, body.truck_charge);

      /*       calculateAndDisplayRoute(originData, destineData);
       */
      setPreviewData({
        ...previewLabels,
      });
    } catch (error) {
      console.error("onLoadEntity", error);
    }
  };

  const handleLoadGoogleMap = async ({
    lat = "-38.4192641",
    lng = "-63.5989206",
  }) => {
    let map = mapRef.current;
    const myLatlng = new googleMapsApi.LatLng(lat.toString(), lng.toString());
    const mapOptions = {
      zoom: 8,
      center: myLatlng,
      scrollwheel: true,
      zoomControl: true,
      mapTypeId: googleMapsApi.MapTypeId.ROADMAP,
      clickableIcons: false,
      disableDefaultUI: true,
      fullscreenControl: true,
    };
    map = new googleMapsApi.Map(map, mapOptions);
    map.addListener("dragend", () => {
      setLocation({
        lat: map.center.lat(),
        lng: map.center.lng(),
      });
    });
  };

  useEffect(() => {
    if (routesDetails) {
      let _kilometers = parseFloat(
        routesDetails.distanceText.split(" ")[0].replace(/,/, "")
      );
      setKilometers(_kilometers);
    }
  }, [routesDetails]);

  useEffect(() => {
    if (googleMapsApi) {
      handleLoadGoogleMap(location);
    }
  }, [googleMapsApi]);

  const calculateAndDisplayRoute = async (
    origin = "penn station, new york, ny",
    destination = "260 Broadway New York NY 10007"
  ) => {
    const lat = "-38.4192641";
    const lng = "-63.5989206";
    let map = mapRef.current;
    const myLatlng = new googleMapsApi.LatLng(lat.toString(), lng.toString());
    const mapOptions = {
      zoom: 13,
      center: myLatlng,
      scrollwheel: false,
      zoomControl: true,
      mapTypeId: googleMapsApi.MapTypeId.ROADMAP,
      clickableIcons: false,
      disableDefaultUI: true,
    };
    map = new googleMapsApi.Map(map, mapOptions);
    // logic market
    const markerArray = [];
    const directionsService = new googleMapsApi.DirectionsService();
    const directionsRenderer = new googleMapsApi.DirectionsRenderer({
      map: map,
    });
    // Instantiate an info window to hold step text.
    // const stepDisplay = new google.maps.InfoWindow();
    // Display the route between the initial start and end selections.
    // First, remove any existing markers from the map.
    for (let i = 0; i < markerArray.length; i++) {
      markerArray[i].setMap(null);
    }
    // Retrieve the start and end locations and create a DirectionsRequest using
    // WALKING directions.
    directionsService
      .route({
        origin,
        destination,
        travelMode: googleMapsApi.TravelMode.DRIVING,
      })
      .then((result) => {
        // Route the directions and pass the response to a function to create
        // markers for each step.
        const { distance, duration } = result.routes[0].legs[0];
        setRoutesDetails({
          distanceText: distance.text,
          durationText: duration.text,
        });
        directionsRenderer.setDirections(result);
      })
      .catch((e) => {
        setRouteError(true);
      });
  };

  const handlePriceWeightByTruck = (weightPrice = 0, Qty = 0) => {
    if (
      isNaN(weightPrice) ||
      isNaN(Qty) ||
      typeof weightPrice !== "number" ||
      typeof Qty !== "number"
    )
      return;
    sePricePerTruck(weightPrice * Qty);
  };

  const handleSubmitAPI = async (values) => {
    const body = {
      ...values,
      kilometers,
    };
    history.push(`/app/traves/view-travel/?preview=true`, {
      body,
      previewData,
    });
  };

  const getFetchTruckCharsSelect = async (value) => {
    setTruckCharac([]);
    let truckCharsArr = [];
    for (const i of value) {
      const resp = await getTruckCharacteristicByTruckIdAPI(i);
      const { truckChars } = resp;
      for (const truckC of truckChars) {
        truckCharsArr.push(truckC);
      }
    }
    truckCharsArr = truckCharsArr.filter(
      (v, i, a) =>
        a.findIndex((t) => JSON.stringify(t) === JSON.stringify(v)) === i
    );
    const truckCharsFormatted = truckCharsArr.map((l) => ({
      _id: l._id,
      label: l.truck_characteristic_name,
      value: l._id,
    }));
    setTruckCharac(truckCharsFormatted);
  };

  return (
    <>
      <Container fluid>
        <Formik
          enableReinitialize
          initialValues={{
            origin_site:
              queryString.parse(locationUrl.search).retrieve_origin ||
              entity.origin_site ||
              "",
            destiny_site:
              queryString.parse(locationUrl.search).retrieve_destine ||
              entity.destiny_site ||
              "",
            additional_origin_indications:
              entity.additional_origin_indications ||
              locationsAPI?.find(
                (ele) =>
                  ele._id ===
                  queryString.parse(locationUrl.search).retrieve_origin
              )?.additional_indications ||
              "",
            additionl_destiny_indications:
              entity.additionl_destiny_indications ||
              locationsAPI?.find(
                (ele) =>
                  ele._id ===
                  queryString.parse(locationUrl.search).retrieve_destine
              )?.additional_indications ||
              "",
            origin:
              entity.origin ||
              locationsAPI?.find(
                (ele) =>
                  ele._id ===
                  queryString.parse(locationUrl.search).retrieve_origin
              )?.origin ||
              "",
            destiny:
              entity.destiny ||
              locationsAPI?.find(
                (ele) =>
                  ele._id ===
                  queryString.parse(locationUrl.search).retrieve_destine
              )?.origin ||
              "",
            withdraw_date: entity.withdraw_date || null,
            withdraw_turn: entity.withdraw_turn || "",
            delivery_date: entity.delivery_date || null,
            delivery_turn: entity.delivery_turn || "",
            product: entity.product || "",
            charger_type: entity.charger_type || "",
            trucks_number: entity.trucks_number || "",
            truck_type: entity.truck_type || [],
            truck_charge: entity.truck_charge || "",
            unit_truck_charge: entity.unit_truck_charge || "",
            truck_features: entity.truck_features || [],
            truck_accessories: entity.truck_accessories || [],
            price_per_ton: entity.price_per_ton || "",
            price_type: entity.price_type || "",
            payment_terms_days: entity.payment_terms_days || "",
            payment_type: entity.payment_type || "",
            requirements_conditions: entity.requirements_conditions || "",
          }}
          validationSchema={TravelSchema}
          onSubmit={(values, { setSubmitting }) => {
            handleSubmitAPI(values);
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            getFieldProps,
            setValues,
            dirty,
          }) => (
            <Row>
              <ContentHeader className={`ml-3`} title={`Crear viaje`} />
              <Col md="12">
                <Card className="shadow-4dp">
                  <Card.Body>
                    <Form>
                      <Row>
                        <Col md="6" className="pr-3 pr-sm-5">
                          <Form.Group>
                            <label className="text-primary font-weight-bold">
                              ¿De donde buscamos tu carga?
                            </label>
                            <LogSelectLocation
                              /* centered={true} */
                              placeholder="Lugar de origen"
                              className="mt-2"
                              name="origin_site"
                              searchable={
                                locationsAPI.length === 0 ? false : true
                              }
                              items={locationsAPI}
                              onChange={(value) => {
                                const {
                                  label,
                                  additional_indications,
                                  origin,
                                } = locationsAPI.find((l) => l._id === value);

                                setValues({
                                  ...values,
                                  origin: origin || "",
                                  additional_origin_indications:
                                    additional_indications || "",
                                  origin_site: value,
                                });

                                setPreviewData((prev) => {
                                  return {
                                    ...prev,
                                    origin_site: label,
                                  };
                                });

                                const { lat, lng } = locationsAPI.find(
                                  (l) => l._id === value
                                );
                                setOriginMap({ lat, lng });
                              }}
                              onBlur={handleBlur.origin_site}
                              errors={errors}
                              value={values.origin_site}
                              footer={
                                <div className="text-center">
                                  <p
                                    className="font-weight-bold text-secondary cursor-pointer"
                                    onClick={() =>
                                      history.push(
                                        `/app/locations/new-location-form?retrieve_origin=true${
                                          values.destiny_site
                                            ? `&destiny_site=${values.destiny_site}`
                                            : ""
                                        }`
                                      )
                                    }
                                  >
                                    Crear localización
                                  </p>
                                </div>
                              }
                            />
                          </Form.Group>
                        </Col>
                        <Col md="6" className="pl-3 mt-2 mt-sm-0 pl-sm-5">
                          <Form.Group>
                            <label className="text-primary font-weight-bold">
                              ¿A donde la llevamos?
                            </label>
                            <LogSelectLocation
                              placeholder="Lugar de destino"
                              className="mt-2"
                              searchable={
                                locationsAPI.length === 0 ? false : true
                              }
                              name="destiny_site"
                              items={locationsAPI}
                              onChange={(value) => {
                                const {
                                  label,
                                  additional_indications,
                                  origin,
                                } = locationsAPI.find((l) => l._id === value);

                                setValues({
                                  ...values,
                                  destiny: origin || "",
                                  additionl_destiny_indications:
                                    additional_indications || "",
                                  destiny_site: value,
                                });

                                setPreviewData((prev) => {
                                  return {
                                    ...prev,
                                    destiny_site: label,
                                  };
                                });
                                const { lat, lng } = locationsAPI.find(
                                  (l) => l._id === value
                                );
                                // {lat: -34, lng: 151}
                                setDestineMap({ lat, lng });
                              }}
                              onBlur={handleBlur.destiny_site}
                              errors={errors}
                              value={values.destiny_site}
                              footer={
                                <div className="text-center">
                                  <p
                                    className="font-weight-bold text-secondary cursor-pointer"
                                    onClick={() =>
                                      history.push(
                                        `/app/locations/new-location-form?retrieve_destine=true${
                                          values.origin_site
                                            ? `&origin_site=${values.origin_site}`
                                            : ""
                                        }`
                                      )
                                    }
                                  >
                                    Crear localización
                                  </p>
                                </div>
                              }
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="p-0 mb-3 mt-2">
                        <Col md="12" className="p-0">
                          <div className="map-container">
                            <div id="map" ref={mapRef}></div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        {routesDetails && (
                          <Col md="12" className="pr-5">
                            <Alert variant="success" className="no-fade">
                              La ruta que acabas seleccionar posee una distancia
                              de{" "}
                              <span className="number-font font-weight-bold">
                                {routesDetails.distanceText}
                              </span>{" "}
                              y tiene una duración apróximada de{" "}
                              <span className="number-font font-weight-bold">
                                {routesDetails.durationText}
                              </span>{" "}
                              en su vehículo
                            </Alert>
                          </Col>
                        )}
                      </Row>
                      <Row>
                        <Col md="6" className="pr-3 pr-sm-5">
                          <Form.Group>
                            <label className="font-weight-bold text-black">
                              Indicaciones adicionales de origen{" "}
                              <span className="text-info">(opcional)</span>
                            </label>
                            <div className="d-flex flex-column justify-content-center justify-content-md-start">
                              <LogTextArea
                                style={{ width: "80%" }}
                                placeholder="Indicaciones adicionales de origen"
                                className="mb-2 number-font"
                                name="additional_origin_indications"
                                onChange={handleChange}
                                onBlur={
                                  handleBlur.additional_origin_indications
                                }
                                errors={errors}
                                value={values.additional_origin_indications}
                                maxLength={400}
                              />
                            </div>
                          </Form.Group>
                        </Col>
                        <Col md="6" className="pl-3 pl-md-5">
                          <label className="font-weight-bold text-black">
                            Indicaciones adicionales de entrega{" "}
                            <span className="text-info">(opcional)</span>
                          </label>
                          <div className="d-flex flex-column justify-content-center justify-content-md-start">
                            <LogTextArea
                              style={{ width: "80%" }}
                              placeholder="Indicaciones adicionales de entrega"
                              className="mb-2 number-font"
                              name="additionl_destiny_indications"
                              onChange={handleChange}
                              onBlur={handleBlur.additionl_destiny_indications}
                              errors={errors}
                              value={values.additionl_destiny_indications}
                              maxLength={400}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6" className="pr-3 pr-sm-5">
                          <LogRadioGroupWrapper
                            name="origin"
                            onBlur={handleBlur.origin}
                            errors={errors}
                            label="Seleccioná un tipo de origen"
                          >
                            <div className="group-container-radios pl-3 mt-2">
                              <LogRadioGroup
                                className="log-radio"
                                name="origen"
                                value="SILO"
                                label="Silo"
                                checked={values.origin === "SILO"}
                                onChange={(value) => {
                                  setFieldValue(
                                    "origin",
                                    value.target.value.toUpperCase()
                                  );
                                }}
                              />
                              <LogRadioGroup
                                className="log-radio"
                                name="origen"
                                value="PLANTA"
                                label="Planta"
                                checked={values.origin === "PLANTA"}
                                onChange={(value) => {
                                  setFieldValue(
                                    "origin",
                                    value.target.value.toUpperCase()
                                  );
                                }}
                              />
                              <LogRadioGroup
                                className="log-radio"
                                name="origen"
                                value="CAMPO"
                                label="Campo"
                                checked={values.origin === "CAMPO"}
                                onChange={(value) => {
                                  setFieldValue(
                                    "origin",
                                    value.target.value.toUpperCase()
                                  );
                                }}
                              />
                              <LogRadioGroup
                                className="log-radio"
                                name="origen"
                                value="PUERTO"
                                label="Puerto"
                                checked={values.origin === "PUERTO"}
                                onChange={(value) => {
                                  setFieldValue(
                                    "origin",
                                    value.target.value.toUpperCase()
                                  );
                                }}
                              />
                            </div>
                          </LogRadioGroupWrapper>
                        </Col>
                        <Col md="6" className="pl-3 pl-sm-5">
                          <LogRadioGroupWrapper
                            name="destiny"
                            onBlur={handleBlur.destiny}
                            errors={errors}
                            label="Seleccioná un tipo de destino"
                          >
                            <div className="group-container-radios pl-3 mt-2">
                              <LogRadioGroup
                                className="log-radio"
                                name="destiny"
                                value="silo"
                                label="Silo"
                                checked={values.destiny === "SILO"}
                                onChange={(value) => {
                                  setFieldValue(
                                    "destiny",
                                    value.target.value.toUpperCase()
                                  );
                                }}
                              />
                              <LogRadioGroup
                                className="log-radio"
                                name="destiny"
                                value="planta"
                                label="Planta"
                                checked={values.destiny === "PLANTA"}
                                onChange={(value) => {
                                  setFieldValue(
                                    "destiny",
                                    value.target.value.toUpperCase()
                                  );
                                }}
                              />
                              <LogRadioGroup
                                className="log-radio"
                                name="destiny"
                                value="campo"
                                label="Campo"
                                checked={values.destiny === "CAMPO"}
                                onChange={(value) => {
                                  setFieldValue(
                                    "destiny",
                                    value.target.value.toUpperCase()
                                  );
                                }}
                              />
                              <LogRadioGroup
                                className="log-radio"
                                name="destiny"
                                value="puerto"
                                label="Puerto"
                                checked={values.destiny === "PUERTO"}
                                onChange={(value) => {
                                  setFieldValue(
                                    "destiny",
                                    value.target.value.toUpperCase()
                                  );
                                }}
                              />
                            </div>
                          </LogRadioGroupWrapper>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6" className="pr-3 pr-sm-5">
                          <Form.Group>
                            <label className="font-weight-bold text-black mb-2 mb-sm-0">
                              Programá el retiro de tu cultivo
                            </label>
                            <Row>
                              <Col md="6 mb-2 mb-sm-0">
                                <LogDatePicker
                                  name="withdraw_date"
                                  className="number-font"
                                  placeholder="Fecha"
                                  onBlur={handleBlur.withdraw_date}
                                  errors={errors}
                                  value={values.withdraw_date}
                                  onChange={(value) => {
                                    setFieldValue("withdraw_date", value);
                                  }}
                                />
                              </Col>
                              <Col md="6 mb-2 mb-sm-0">
                                <LogTimerPicker
                                  placeholder="Turno"
                                  name="withdraw_turn"
                                  items={turns}
                                  onChange={(value) => {
                                    setFieldValue("withdraw_turn", value);
                                  }}
                                  onBlur={handleBlur.withdraw_turn}
                                  value={values.withdraw_turn}
                                  errors={errors}
                                />
                              </Col>
                            </Row>
                          </Form.Group>
                        </Col>
                        <Col md="6" className="pl-3 pl-sm-5">
                          <label className="font-weight-bold text-black mb-2 mb-sm-0">
                            Programá la entrega de tu cultivo
                          </label>
                          <Row>
                            <Col md="6 mb-2 mb-sm-0">
                              <LogDatePicker
                                name="delivery_date"
                                placeholder="Fecha"
                                className="number-font"
                                value={values.delivery_date}
                                onChange={(value) => {
                                  setFieldValue("delivery_date", value);
                                }}
                                errors={errors}
                              />
                            </Col>
                            <Col md="6 mb-2 mb-sm-0">
                              <LogTimerPicker
                                placeholder="Turno"
                                name="delivery_turn"
                                items={turns}
                                onChange={(value) => {
                                  setFieldValue("delivery_turn", value);
                                }}
                                onBlur={handleBlur.delivery_turn}
                                value={values.delivery_turn}
                                errors={errors}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>

              <Col md="12">
                <Card className="card-log-design shadow-4dp mt-4 p-2">
                  <Card.Body>
                    <Form>
                      <Row>
                        <Col md="6" className="pr-3 pr-sm-5">
                          <h1 className="h6 text-primary font-weight-bold mb-0">
                            ¿Qué tipo de producto querés trasladar?
                          </h1>
                          <LogSelect
                            placeholder="Seleccioná un producto"
                            className="mt-3"
                            name="product"
                            items={productTypes}
                            onChange={(value) => {
                              setFieldValue("product", value);

                              try {
                                const { label } = productTypes.find(
                                  (l) => l._id === value
                                ) || { label: "" };
                                setPreviewData((prev) => {
                                  return {
                                    ...prev,
                                    product: label,
                                  };
                                });
                              } catch (error) {
                                console.error("debug error:", error);
                              }
                            }}
                            onBlur={handleBlur.product}
                            errors={errors}
                            value={values.product}
                          />
                        </Col>
                        <Col md="6" className="mt-2 mt-sm-0 pl-3 pl-sm-5">
                          <h1 className="h6 text-primary font-weight-bold mb-0">
                            ¿Qué tipo de carga necesitás?
                          </h1>
                          <LogSelect
                            placeholder="¿Qué tipo de carga necesitás?"
                            className="mt-3"
                            name="charger_type"
                            items={chargeTypes}
                            onChange={(v) => {
                              setFieldValue("charger_type", v);

                              const { label } = chargeTypes.find(
                                (l) => l._id === v
                              ) || { label: "" };
                              setPreviewData((prev) => {
                                return {
                                  ...prev,
                                  charger_type: label,
                                };
                              });
                            }}
                            onBlur={handleBlur.charger_type}
                            errors={errors}
                            value={values.charger_type}
                          />
                        </Col>
                      </Row>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>

              <Col md="12">
                <Card className="card-log-design shadow-4dp mt-4">
                  <Card.Header className="pl-0 pt-4 pr-0 ml-4 mr-4">
                    <label className="text-primary font-weight-bold mb-0">
                      Camiones
                    </label>
                  </Card.Header>
                  <Card.Body>
                    <Form>
                      <Row>
                        <Col md="6" className="pr-3 pr-sm-5">
                          <label className="font-weight-bold text-black">
                            ¿Cuántos camiones necesitas?
                          </label>
                          <LogInput
                            className="number-font"
                            placeholder="Ingresá un número"
                            type="text"
                            name="trucks_number"
                            onChange={(e) => {
                              const re = /^[0-9\b]+$/;
                              if (
                                e.target.value === "" ||
                                re.test(e.target.value)
                              ) {
                                const number = parseInt(e.target.value || 0);
                                setFieldValue("trucks_number", number);
                              }
                            }}
                            onBlur={handleBlur.trucks_number}
                            errors={errors}
                            value={values.trucks_number}
                            maxLength={3}
                          />
                        </Col>
                        <Col md="6" className="pl-3 pl-sm-5 mb-2 mb-0">
                          <label className="font-weight-bold text-black">
                            Seleccioná un tipo de camión
                          </label>

                          <LogSelectMulti
                            placeholder="Tipo de camión"
                            name="truck_type"
                            items={truckTypes}
                            onChange={async (value) => {
                              getFetchTruckCharsSelect(value);
                              setFieldValue("truck_type", value);

                              let arr = [];
                              value.forEach((v) => {
                                const { label } = truckTypes.find(
                                  (l) => l._id === v
                                );
                                arr.push(label);
                              });

                              setPreviewData((prev) => {
                                return {
                                  ...prev,
                                  truck_type: arr,
                                };
                              });
                            }}
                            onBlur={handleBlur.truck_type}
                            errors={errors}
                            value={values.truck_type}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6" className="pr-3 pr-sm-5">
                          <label className="font-weight-bold text-black">
                            Cantidad de carga por camión
                          </label>
                          <Row>
                            <Col md="7" className="mb-1 mb-sm-0">
                              <LogInput
                                className="number-font"
                                placeholder="Ingresá un número"
                                type="text"
                                name="truck_charge"
                                onChange={(e) => {
                                  const { value } = e.target;
                                  const re = /^[0-9\b]+$/;
                                  if (value === "" || re.test(value)) {
                                    const number = parseFloat(value || 0);
                                    setFieldValue("truck_charge", number);
                                    handlePriceWeightByTruck(
                                      parseFloat(
                                        getFieldProps("price_per_ton").value
                                      ),
                                      number
                                    );
                                  } else {
                                    setFieldValue("truck_charge", 0);
                                    handlePriceWeightByTruck(
                                      parseFloat(
                                        getFieldProps("price_per_ton").value
                                      ),
                                      0
                                    );
                                  }
                                }}
                                onBlur={handleBlur.truck_charge}
                                errors={errors}
                                value={values.truck_charge}
                              />
                            </Col>
                            <Col md="5" className="mb-2 mb-sm-0">
                              {" "}
                              <LogSelect
                                placeholder="Unidad de medida"
                                name="unit_truck_charge"
                                items={units}
                                onChange={(v) => {
                                  setFieldValue("unit_truck_charge", v || "");

                                  const { label } = units.find(
                                    (l) => l.value === v
                                  ) || { label: "" };
                                  setPreviewData((prev) => {
                                    return {
                                      ...prev,
                                      unit_truck_charge: label,
                                    };
                                  });
                                }}
                                onBlur={handleBlur.unit_truck_charge}
                                errors={errors}
                                value={values.unit_truck_charge}
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col md="6" className="pl-3 pl-sm-5 mb-2 mb-0">
                          <label className="font-weight-bold text-black">
                            Seleccioná las características (opcional)
                          </label>
                          <LogSelectMulti
                            placeholder="Características"
                            name="truck_features"
                            className="mt-2"
                            items={truckCharac}
                            onChange={(v) => {
                              setFieldValue("truck_features", v);

                              let arr = [];
                              v.forEach((_v) => {
                                const { label } = truckCharac.find(
                                  (l) => l._id === _v
                                );
                                arr.push(label);
                              });

                              setPreviewData((prev) => {
                                return {
                                  ...prev,
                                  truck_features: arr,
                                };
                              });
                            }}
                            onBlur={handleBlur.truck_features}
                            disabled={truckCharac.length === 0}
                            errors={errors}
                            value={values.truck_features}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <label className="font-weight-bold text-black">
                            Elegí los accesorios (opcional)
                          </label>
                          <LogSelectMulti
                            placeholder="Accesorios"
                            name="truck_accessories"
                            items={truckAccesories}
                            onChange={(value) => {
                              setFieldValue("truck_accessories", value);

                              let arr = [];
                              value.forEach((_v) => {
                                const { label } = truckAccesories.find(
                                  (l) => l._id === _v
                                );
                                arr.push(label);
                              });

                              setPreviewData((prev) => {
                                return {
                                  ...prev,
                                  truck_accessories: arr,
                                };
                              });
                            }}
                            onBlur={handleBlur.truck_accessories}
                            errors={errors}
                            value={values.truck_accessories}
                          />
                        </Col>
                      </Row>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
              <Col md="12">
                <Card className="card-log-design shadow-4dp mt-4">
                  <Card.Header className="pl-0 pt-4 pr-0 ml-4 mr-4">
                    <label className="text-primary font-weight-bold mb-0">
                      Precios y condiciones{" "}
                      <small className="text-font-info">
                        ({TAXES_LABEL || "no incluyen IVA"})
                      </small>
                    </label>
                  </Card.Header>
                  <Card.Body>
                    <Form>
                      <Row>
                        <Col md="3">
                          <label className="font-weight-bold text-black">
                            {"Precio por " +
                              (values.unit_truck_charge
                                ? convertUnit(values.unit_truck_charge)
                                : "Tonelada")}
                          </label>
                          <LogInputCurrency
                            className="number-font"
                            placeholder="AR$"
                            type="text"
                            name="price_per_ton"
                            thousandSeparator={true}
                            prefix={"$ "}
                            onValueChange={(values) => {
                              const { value } = values;
                              const re = /^[0-9\b]+$/;
                              if (value.value === "" || re.test(value)) {
                                const number = parseFloat(value || 0);
                                setFieldValue("price_per_ton", number);
                                handlePriceWeightByTruck(
                                  number,
                                  parseFloat(
                                    getFieldProps("truck_charge").value
                                  )
                                );
                              } else {
                                setFieldValue("price_per_ton", 0);
                                handlePriceWeightByTruck(
                                  0,
                                  parseFloat(
                                    getFieldProps("truck_charge").value
                                  )
                                );
                              }
                            }}
                            onBlur={handleBlur.price_per_ton}
                            errors={errors}
                            value={values.price_per_ton}
                          />
                        </Col>
                        <Col md="3" className="pr-3 pr-sm-5 mt-2 mt-sm-0">
                          <label className="font-weight-bold text-black">
                            Precio por camión
                          </label>
                          <LogInputCurrency
                            placeholder="$"
                            type="text"
                            name="pricePerTruck"
                            className="number-font"
                            disabled={true}
                            thousandSeparator={true}
                            prefix={"$ "}
                            onChange={(e) => {
                              const re = /^[0-9\b]+$/;
                              if (
                                e.target.value === "" ||
                                re.test(e.target.value)
                              ) {
                                const number = parseFloat(e.target.value || 0);
                                setFieldValue("pricenumber");
                                handlePriceWeightByTruck(
                                  number,
                                  parseFloat(
                                    getFieldProps("truck_charge").value
                                  )
                                );
                              }
                            }}
                            onBlur={handleBlur.pricePerTruck}
                            errors={errors}
                            value={pricePerTruck}
                            maxLength={9}
                          />
                        </Col>
                        <Col md="6" className="pl-3 mt-2 mt-sm-0 pl-sm-5">
                          <label className="font-weight-bold text-black">
                            Plazo Efectivo de Pago (en días)
                          </label>
                          <LogInput
                            className="number-font"
                            placeholder="Ingresá un número"
                            type="text"
                            name="payment_terms_days"
                            onChange={(e) => {
                              const re = /^[0-9\b]+$/;
                              if (
                                e.target.value === "" ||
                                re.test(e.target.value)
                              ) {
                                const number = parseFloat(e.target.value || 0);
                                setFieldValue("payment_terms_days", number);
                              }
                            }}
                            onBlur={handleBlur.payment_terms_days}
                            errors={errors}
                            value={values.payment_terms_days}
                            maxLength={2}
                          />
                        </Col>
                        <Col md="6" className="pt-0 pb-0">
                          <LogRadioGroupWrapper
                            name="price_type"
                            onBlur={handleBlur.price_type}
                            errors={errors}
                            label=""
                          >
                            <div className="mt-2">
                              <Row className="ml-0">
                                <OverlayTrigger
                                  placement={"top"}
                                  overlay={
                                    <Tooltip id={`tooltip-left`}>
                                      Se permiten{" "}
                                      <strong>
                                        realizar o recibir ofertas
                                      </strong>
                                      .
                                    </Tooltip>
                                  }
                                >
                                  <Col md="6">
                                    <LogRadioGroup
                                      className="log-radio"
                                      name="price_type"
                                      value="1"
                                      label="Precio abierto"
                                      checked={values.price_type === "1"}
                                      onChange={(value) => {
                                        setFieldValue(
                                          "price_type",
                                          value.target.value.toUpperCase()
                                        );
                                      }}
                                    />
                                  </Col>
                                </OverlayTrigger>
                                <OverlayTrigger
                                  placement={"top"}
                                  overlay={
                                    <Tooltip id={`tooltip-left`}>
                                      No se pueden{" "}
                                      <strong>realizar ofertas</strong>.
                                    </Tooltip>
                                  }
                                >
                                  <Col md="6">
                                    <LogRadioGroup
                                      className="log-radio"
                                      name="price_type"
                                      value="2"
                                      label="Precio cerrado"
                                      checked={values.price_type === "2"}
                                      onChange={(value) => {
                                        setFieldValue(
                                          "price_type",
                                          value.target.value.toUpperCase()
                                        );
                                      }}
                                    />
                                  </Col>
                                </OverlayTrigger>
                              </Row>
                            </div>
                          </LogRadioGroupWrapper>
                        </Col>
                      </Row>
                      <Row>
                        <OverlayTrigger
                          placement={"top"}
                          overlay={
                            <Tooltip id={`tooltip-left`}>
                              Número Telefónico al cual el Chofer podrá{" "}
                              <strong>comunicarse </strong>una vez comenzado el
                              viaje.
                            </Tooltip>
                          }
                        >
                          <Col md="4" className="pr-1">
                            <label className="font-weight-bold text-black">
                              Datos de Logística
                            </label>
                            <LogInputPhone
                              placeholder="Teléfono Contacto"
                              className="mb-2"
                              name="logis_number_dc"
                              onChange={(phoneNumber) => {
                                if (
                                  phoneNumber &&
                                  !phoneNumber.startsWith("+")
                                ) {
                                  phoneNumber = "+" + phoneNumber;
                                }
                                setFieldValue("logis_number_dc", phoneNumber);
                              }}
                              onBlur={handleBlur.logis_number_dc}
                              errors={errors}
                              value={values.logis_number_dc}
                            />
                          </Col>
                        </OverlayTrigger>
                        <Col md="4" className="pr-2">
                          <label className="font-weight-bold text-black">
                            Forma de pago
                          </label>
                          <LogSelect
                            name="payment_type"
                            placeholder="Forma de pago"
                            items={paymentTypeAPI}
                            onChange={(v) => {
                              setFieldValue("payment_type", v);
                              const { label } = paymentTypeAPI.find(
                                (l) => l.value === v
                              ) || { label: "" };
                              setPreviewData((prev) => {
                                return {
                                  ...prev,
                                  payment_type: label,
                                };
                              });
                            }}
                            onBlur={handleBlur.payment_type}
                            errors={errors}
                            value={values.payment_type}
                          />
                        </Col>
                        <Col md="4" className="pl-3 mt-2 mt-sm-0 pl-sm-1">
                          <label className="font-weight-bold text-black">
                            Requisitos y condiciones
                          </label>
                          <div>
                            <LogTextArea
                              className="mb-2 number-font"
                              name="requirements_conditions"
                              onChange={handleChange}
                              onBlur={handleBlur.requirements_conditions}
                              errors={errors}
                              value={values.requirements_conditions}
                              maxLength={400}
                            />
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
              <Col md="4">
                <LogButton
                  className="mt-3 w-100"
                  size="lg"
                  title="Continuar"
                  border="curve"
                  onClick={() => {
                    if (dirty) {
                      if (Object.keys(errors)?.length > 0) {
                        errorToast("Revise campos faltantes");
                      }
                      handleSubmit();
                    } else {
                      errorToast("Introduzca algún cambio");
                    }
                  }}
                />
                <div className="clearfix"></div>
              </Col>
            </Row>
          )}
        </Formik>
        <ModalInfo
          title="Ruta no encontrada"
          subTitle="Por favor busca otra ubicacion ya que no encontramos rutas entre origen y destino"
          isShow={routeError}
          handleClose={() => setRouteError(false)}
          icon={<AiOutlineCloseCircle className="display-3 text-danger mb-3" />}
          permanent
          okText="Aceptar"
          colorText="text-danger"
        />
      </Container>
    </>
  );
}

export default NewTravel;
