const OnlyNumber = (i) => {
  if (i.value.length > 0) {
    i.value = i.value.replace(/[^\d]+/g, "");
  }
};

const typePriceString = (type) => {
  let response;
  switch (type) {
    case "1":
      response = "Abierto";
      break;
    case "2":
      response = "Cerrado";
      break;
    default:
      response = "Abierto";
      break;
  }
  return response;
};

const getTypeTruck = (type) => {
  let response;
  switch (type) {
    case "1":
      response = "Camión";
      break;
    case "2":
      response = "Acoplado";
      break;
    default:
      response = "Camión";
      break;
  }
  return response;
};

const turnString = (type) => {
  let response;
  switch (type) {
    case "M":
      response = "Mañana";
      break;
    case "A":
      response = "Tarde";
      break;
    case "N":
      response = "Noche";
      break;
    default:
      response = "Mañana";
      break;
  }
  return response;
};
const payFormString = (type) => {
  let response;
  switch (type) {
    case "check":
      response = "Cheque";
      break;
    case "transfer":
      response = "Transferencia";
      break;

    default:
      response = "Cheque";
      break;
  }
  return response;
};
const priceCalculation = (number, percent = 0, open = false) => {
  let result = number;
  let formatter;
  if (!open) {
    result -= number * (percent / 100);
  }
  if (result >= 1000) {
    formatter = new Intl.NumberFormat("es-AR", {
      style: "currency",
      currency: "ARS",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  } else {
    formatter = new Intl.NumberFormat("es-AR", {
      style: "currency",
      currency: "ARS",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
  return formatter.format(result);
};

const uploadImagesToCloudinary = async (images) => {
  if (!Array.isArray(images)) {
    images = [images];
  }
  const uploadImage = async (image) => {
    const data = new FormData();
    data.append("file", image);
    data.append("upload_preset", "ceqdn88c");

    try {
      const res = await fetch(
        "https://api.cloudinary.com/v1_1/dcbqcvers/image/upload",
        {
          body: data,
          method: "POST",
        }
      );
      if (!res.ok) {
        const errorText = await res.text();
        throw new Error(`Error ${res.status}: ${errorText}`);
      }
      const file = await res.json();
      return file.secure_url;
    } catch (error) {
      console.log("Error al subir la imagen", error);
      throw error;
    }
  };

  const promises = images.map(uploadImage);
  return Promise.all(promises);
};

const resetCurrency = (input) => {
  let formatted = input.replace(/[^0-9,.-]+/g, "");
  formatted = formatted.replace(/\./g, "");
  formatted = formatted.replace(",", ".");

  return formatted;
};

const taxStatus = ["Responsable Inscripto", "Monotributo"].map((item) => ({
  label: item,
  value: item,
}));
const numberWords = [
  // Español
  "cero",
  "uno",
  "dos",
  "tres",
  "cuatro",
  "cinco",
  "seis",
  "siete",
  "ocho",
  "nueve",
  "diez",
  "once",
  "doce",
  "trece",
  "catorce",
  "quince",
  "dieciséis",
  "veinte",
  "treinta",
  "cuarenta",
  "cincuenta",
  "sesenta",
  "setenta",
  "ochenta",
  "noventa",
  "cien",
  "ciento",
  "doscientos",
  "trescientos",
  "cuatrocientos",
  "quinientos",
  "seiscientos",
  "setecientos",
  "ochocientos",
  "novecientos",
  "mil",
  "millón",

  // Portugués
  "zero",
  "um",
  "dois",
  "três",
  "quatro",
  "cinco",
  "seis",
  "sete",
  "oito",
  "nove",
  "dez",
  "onze",
  "doze",
  "treze",
  "catorze",
  "quinze",
  "dezesseis",
  "vinte",
  "trinta",
  "quarenta",
  "cinquenta",
  "sessenta",
  "setenta",
  "oitenta",
  "noventa",
  "cem",
  "cento",
  "duzentos",
  "trezentos",
  "quatrocentos",
  "quinhentos",
  "seiscentos",
  "setecentos",
  "oitocentos",
  "novecentos",
  "mil",
  "milhão",

  // Inglés
  "zero",
  "one",
  "two",
  "three",
  "four",
  "five",
  "six",
  "seven",
  "eight",
  "nine",
  "ten",
  "eleven",
  "twelve",
  "thirteen",
  "fourteen",
  "fifteen",
  "sixteen",
  "twenty",
  "thirty",
  "forty",
  "fifty",
  "sixty",
  "seventy",
  "eighty",
  "ninety",
  "hundred",
  "thousand",
  "million",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "0",
];


const numberRegex = new RegExp(`(${numberWords.join("|")}|\\d+)`, "gi");

export {
  OnlyNumber,
  typePriceString,
  turnString,
  payFormString,
  getTypeTruck,
  priceCalculation,
  uploadImagesToCloudinary,
  resetCurrency,
  taxStatus,
  numberRegex,
};
